import React, { useState, useEffect } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { HiLockClosed } from "react-icons/hi2";

export const Autocomplete = ({
  suggestions,
  fields,
  keyField,
  value,
  id,
  label,
  onChangeFunc,
  disabled,
  icon,
}) => {
  const [active, setActive] = useState(0);
  const [filtered, setFiltered] = useState([]);
  const [isShow, setIsShow] = useState(false);
  const [input, setInput] = useState("");
  const normalizeString = (str) => {
    const accentsMap = {
      À: "A",
      Á: "A",
      Â: "A",
      Ã: "A",
      Ä: "A",
      Å: "A",
      à: "a",
      á: "a",
      â: "a",
      ã: "a",
      ä: "a",
      å: "a",
      È: "E",
      É: "E",
      Ê: "E",
      Ë: "E",
      è: "e",
      é: "e",
      ê: "e",
      ë: "e",
      Ì: "I",
      Í: "I",
      Î: "I",
      Ï: "I",
      ì: "i",
      í: "i",
      î: "i",
      ï: "i",
      Ò: "O",
      Ó: "O",
      Ô: "O",
      Õ: "O",
      Ö: "O",
      ò: "o",
      ó: "o",
      ô: "o",
      õ: "o",
      ö: "o",
      Ù: "U",
      Ú: "U",
      Û: "U",
      Ü: "U",
      ù: "u",
      ú: "u",
      û: "u",
      ü: "u",
      ÿ: "y",
      Ñ: "N",
      ñ: "n",
      // Add other characters as needed
    };
    return str
      .split("")
      .map((char) => accentsMap[char] || char)
      .join("")
      .toLowerCase();
  };
  const onChange = (e) => {
    const input = e.currentTarget.value;
    const normalizedInput = normalizeString(input);
    let newFilteredSuggestions = [];
    if (input !== "") {
      newFilteredSuggestions = suggestions.filter((suggested) => {
        //questo è il filtro
        return Object.keys(suggested).some((prop) => {
          //uso le key
          if (fields.indexOf(prop) != -1) {
            //Controllo soltanto le proprietà che voglio filtrare senza tenere in considerazione se è una lettera speciale
            const normalizedValue = normalizeString(String(suggested[prop]));
            return normalizedValue.indexOf(normalizedInput) !== -1;
          } else {
            return false;
          }
        });
      });
    } else {
      newFilteredSuggestions = suggestions;
    }
    setFiltered(newFilteredSuggestions);
    setActive(0);
    setIsShow(true);
    setInput(e.currentTarget.value);
  };

  useEffect(() => {
    const fetchData = async () => {
      if (value !== null && value !== undefined) {
        let el = suggestions.filter((el) => {
          return el[keyField] === value;
        })[0];
        if (el) {
          let currentVal = fields.map((f) => el[f]);
          setInput(currentVal.join(" "));
        }
      } else {
        setInput("");
      }
      await setFiltered(suggestions);
    };
    fetchData();
  }, [suggestions, value]); //li aggiorna quando cambiano valore

  const onClick = async (e) => {
    let value = e.currentTarget.value;
    setActive(0);
    setFiltered([]);
    setIsShow(false);
    setInput(e.currentTarget.innerText);
    await onChangeFunc(value);
  };
  const onKeyDown = (e) => {
    if (e.keyCode === 13) {
      // enter key
      setActive(0);
      setIsShow(false);
      setInput(filtered[active]);
    } else if (e.keyCode === 38) {
      // up arrow
      return active === 0 ? null : setActive(active - 1);
    } else if (e.keyCode === 40) {
      // down arrow
      return active - 1 === filtered.length ? null : setActive(active + 1);
    }
  };

  //LISTA SUGGESTIONS
  const renderAutocomplete = () => {
    if (isShow) {
      if (filtered.length) {
        return (
          <>
            <ul className=" bg-gray-100 uppercase border text-gray-600 text-base font-medium w-full overflow-y-auto max-h-[200px] absolute z-[110] shadow-md rounded-md">
              {filtered.map((suggestion, index) => {
                let className =
                  "block px-4 py-2 border-b border w-full hover:bg-white hover:text-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-700 focus:text-primary-700 cursor-pointer";
                if (index === active) {
                  className += " active";
                }
                return (
                  <li
                    className={className}
                    key={suggestion[keyField]}
                    onClick={onClick}
                    value={suggestion[keyField]}
                  >
                    {fields.map(
                      (f) =>
                        suggestion[f] &&
                        suggestion[f].charAt(0).toUpperCase() +
                          suggestion[f].toLowerCase().slice(1) +
                          " "
                    )}
                  </li>
                );
              })}
            </ul>
          </>
        );
      } else {
        return (
          <div className="bg-gray-100 border text-gray-900 text-sm font-medium w-full overflow-auto max-h-[300px] absolute  z-[110]">
            <li className="block pr-4 pl-10 py-2 border-b border-slate-300 w-full hover:bg-white hover:text-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-700 focus:text-primary-700 cursor-pointer">
              Nessun risultato
            </li>
          </div>
        );
      }
    }
    return <></>;
  };

  window.addEventListener("click", function (e) {
    if (document.getElementById(id)?.contains(e.target)) {
      setActive(0);
      setIsShow(true);
    } else {
      setActive(0);
      setIsShow(false);
    }
  });

  return (
    <div
      className="relative w-full" // l'absolute figlio si riferisce il relative padre
      onClick={() => setIsShow(false)}
    >
      <span className="text-base font-normal left-2 -top-2 flex items-center gap-1">
        {label}
        {disabled && <HiLockClosed className="" />}
      </span>
      <label className="relative block " onClick={(e) => e.stopPropagation()}>
        <span className="absolute left-2 flex items-center pr-2 h-full">
          {icon}
        </span>
        <input
          autoComplete="off"
          className={"select"}
          type="text"
          placeholder={input !== "" ? input : "Seleziona " + label}
          onClick={onChange}
          onChange={onChange}
          onKeyDown={onKeyDown}
          value={input}
          id={id + "_" + label}
          disabled={disabled}
        />
        <div
          className="absolute top-2/4 right-3 grid h-5 w-5 -translate-y-2/4 place-items-center text-black cursor-pointer"
          onClick={() => {
            if (!disabled) {
              setInput("");
              onChangeFunc(null);
            }
          }}
        >
          <AiOutlineClose className="button-icon hover:scale-125 transition-all" />
        </div>
      </label>
      {renderAutocomplete()}
    </div>
  );
};

/*
<CustomAutocomplete
    suggestions={lavorazioniAutocomplete} --> ARRAY DI OGGETTI 
    fields={["Lavorazione"]} --> PROPRIETA' DELL'OGGETTO
    keyField="ID" --> id dell'oggetto all'interno dell'array "suggestions"
    value={lavorazioneAssociata.IDLav} --> VALORE OGGETTO SELEZIONATO
    id="Lavorazione" 
    label="Lavorazione"
    name="Lavorazione"
    onChange={(e) => {
    let lav = { ...lavorazioneAssociata };
    lav.IDLav = e;
    setLavorazioneAssociata(lav);
    }}
/>




const newFilteredSuggestions = suggestions.filter(
      suggestion =>
        suggestion.toLowerCase().indexOf(input.toLowerCase()) > -1
    );



*/
