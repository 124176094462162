import { BarGraph, PieGraph } from "../components/Grafici";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { Tooltip, Typography } from "@mui/material";
import { FaCircle } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { Amministratore } from "../utility/useAmministraotre";
import { useContext, useEffect, useState } from "react";
import api from "../components/api/api";
import moment from "moment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";

function splitString(str, chunkSize) {
  var chunks = [];
  for (var i = 0; i < str.length; i += chunkSize) {
    chunks.push(str.substring(i, i + chunkSize));
  }
  return chunks;
}

const columns = [
  {
    filterable: false,
    field: "Stato",
    headerName: "Stato",
    width: 80,
    editable: false,
    align: "center",
    headerAlign: "center",
    renderCell: (params) => {
      let color = params.row.stato === "" ? "grey" : params.row.stato;
      let tooltip = "grey";
      switch (params.row.stato) {
        case "grey":
          tooltip = "Chiuso";
          color = "#B1B3B3";
          break;
        case "red":
          color = "#D3000090";
          tooltip = "In attesa di documentazione";
          break;
        case "purple":
          color = "#6600A190";
          tooltip = "Creare ed inviare denuncia";
          break;
        case "yellow":
          color = "#F6CD4C";
          tooltip = "Denuncia inviata";
          break;
        case "green":
          color = "#2d8a81";
          tooltip = "Atto da firmare inviato";
          break;
        case "lightgrey":
          color = "#dedede";
          tooltip = "Pratica da gestire";
          break;
        case "celeste":
          color = "#99cbff";
          tooltip = "Da completare";
          break;

        default:
          break;
      }
      return (
        <Tooltip title={tooltip}>
          <div className="w-full h-full flex items-center justify-center">
            <FaCircle className={`text-3xl`} color={color} />
          </div>
        </Tooltip>
      );
    },
  },
  {
    field: "riferimento",
    headerName: "Rif.",
    width: 50,
    editable: false,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "amministratore",
    headerName: "Amministratore",
    width: 100,
    editable: false,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "data",
    headerName: "Data",
    width: 100,
    editable: false,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "condominio",
    headerName: "Condominio",
    width: 150,
    editable: false,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "appunti",
    headerName: "Appunti",
    width: 300,
    editable: false,
    align: "center",
    headerAlign: "center",
    renderCell: (params) => {
      var resultArray = splitString(params.row.appunti, 35);
      return (
        <Tooltip
          className="cursor-pointer "
          placement="left-start"
          title={<div className="text-sm">{params.row.appunti}</div>}
        >
          {resultArray.map((el) => (
            <Typography
              fontSize={14}
              lineHeight={1}
              className="text-left text-sx"
            >
              {el}
            </Typography>
          ))}
        </Tooltip>
      );
    },
  },
];

const labels = [
  "Gen",
  "Feb",
  "Mar",
  "Apr",
  "Mag",
  "Giu",
  "Lug",
  "Ago",
  "Set",
  "Ott",
  "Nov",
  "Dic",
];

export const Home = () => {
  const navigate = useNavigate();
  const [amministratore, setAmministratore] = useContext(Amministratore);
  const [anno, setAnno] = useState(new Date());
  const [sinistriAperte, setSinistriAperte] = useState([]);
  const [sinistriDaAprire, setSinistriDaAprire] = useState([]);
  const [sinistriAnalytics, setSinistriAnalytics] = useState([]);
  const [sinistriAnalyticsBar, setSinistriAnalyticsBar] = useState([]);

  const [filterDateDa, setFilterDateDa] = useState(new Date());
  const [filterDateA, setFilterDateA] = useState(new Date());

  function handleStato(params, col) {
    let tooltip;
    let color;
    switch (params) {
      case "grey":
        tooltip = "Chiuso";
        color = "#B1B3B3";
        break;
      case "red":
        color = "#D3000090";
        tooltip = "In attesa di documentazione";
        break;
      case "purple":
        color = "#6600A190";
        tooltip = "Creare ed inviare denuncia";
        break;
      case "yellow":
        color = "#F6CD4C";
        tooltip = "Denuncia inviata";
        break;
      case "green":
        color = "#2d8a81";
        tooltip = "Atto da firmare inviato";
        break;
      case "lightgrey":
        color = "#dedede";
        tooltip = "Pratica da gestire";
        break;
      case "celeste":
        color = "#99cbff";
        tooltip = "Da completare";
        break;

      default:
        break;
    }
    if (col) {
      return color;
    } else {
      return tooltip;
    }
  }

  const handleSinistriByYear = async (annoDa, annoA) => {

    console.log(amministratore)
    console.log(sessionStorage.getItem("amministratore"))
    let responseSinistri;
    let responseSinistriAnalytics;
    let responseSinistriAnalyticsBar;
    if (sessionStorage.getItem("amministratore") !== "0") {
      const urlParams = new URLSearchParams();
      urlParams.append("id", sessionStorage.getItem("amministratore"));
      urlParams.append("da", moment(annoDa).format("YYYY"));
      urlParams.append("a", moment(annoA).format("YYYY"));
      responseSinistri = await api.get(`sinistri`, { params: urlParams });
      responseSinistriAnalytics = await api.get("sinistri/analytics", {
        params: urlParams,
      });
      responseSinistriAnalyticsBar = await api.get("sinistri/analyticsBar", {
        params: urlParams,
      });
    } else {
      const urlParams = new URLSearchParams();
      urlParams.append("da", moment(annoDa).format("YYYY"));
      urlParams.append("a", moment(annoA).format("YYYY"));
      responseSinistri = await api.get("sinistri/all", { params: urlParams });
      responseSinistriAnalytics = await api.get("sinistri/analytics/all", {
        params: urlParams,
      });
      responseSinistriAnalyticsBar = await api.get(
        "sinistri/analyticsBar/all",
        {
          params: urlParams,
        }
      );
    }
    setSinistriAnalytics(responseSinistriAnalytics.data.data);
    setSinistriAperte(
      responseSinistri.data.data?.filter((el) => {
        if (amministratore !== 0) {
          return (
            el.id_amministratore === amministratore &&
            (el.stato === "yellow" || el.stato === "green" || el.stato === "celeste")
          );
        } else {
          return el.stato === "yellow" || el.stato === "green" || el.stato === "celeste";
        }
      })
    );
    setSinistriDaAprire(
      responseSinistri.data.data?.filter((el) => {
        if (amministratore !== 0) {
          return (
            el.id_amministratore === amministratore &&
            (el.stato === "red" || el.stato === "purple")
          );
        } else {
          return el.stato === "red" || el.stato === "purple";
        }
      })
    );

    // Define the range of months you are interested in
    const months = Array.from({ length: 12 }, (_, i) => i + 1);

    // Find unique states
    const states = [
      ...new Set(
        responseSinistriAnalyticsBar.data.data?.map((item) => item.stato)
      ),
    ];

    // Create the result array
    const result = states.map((stato) => {
      const counts = months.map((month) => {
        const entry = responseSinistriAnalyticsBar.data.data?.find(
          (item) => item.stato === stato && item.month === month
        );
        return entry ? entry.count : 0;
      });

      return {
        label: handleStato(stato),
        data: counts,
        backgroundColor: handleStato(stato, true),
      };
    });
    setSinistriAnalyticsBar(result);
  };

  useEffect(() => {
    (async () => {
      handleSinistriByYear(filterDateDa, filterDateA);
    })();
  }, [amministratore, filterDateDa, filterDateA]);

  return (
    <div className="page-container">
      <div className="grid grid-cols-2 gap-x-2 gap-y-2">
        <div className="col-span-2 flex justify-center">
          <div className=" w-fit flex gap-5 mt-2">
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DatePicker
                label="Dall'anno"
                views={["year"]}
                openTo="year"
                className="w-full bg-white  hover:border-black border border-white text-black"
                onChange={(e) => {
                  handleSinistriByYear(
                    moment(e).format("YYYY"),
                    moment(filterDateA).format("YYYY")
                  );
                  setFilterDateDa(e);
                }}
                value={moment(filterDateDa)}
              />
            </LocalizationProvider>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DatePicker
               label="Dall'anno"
                views={["year"]}
                openTo="year"
                className="w-full bg-white  hover:border-black border border-white text-black"
                onChange={(e) => {
                  handleSinistriByYear(
                    moment(filterDateDa).format("YYYY"),
                    moment(e).format("YYYY")
                  );
                  setFilterDateA(e);
                }}
                value={moment(filterDateA)}
              />
            </LocalizationProvider>
          </div>
        </div>
        <div className="col-span-2 lg:col-span-1 flex items-center justify-center h-72 2xl:h-96">
          {sinistriAnalyticsBar?.length > 0 && (
            <BarGraph
              data={{
                labels,
                datasets: [...sinistriAnalyticsBar],
              }}
            />
          )}
        </div>
        <div className="col-span-2 lg:col-span-1 flex items-center justify-center h-72 2xl:h-96">
          <div className="text-lg font-semibold ">{/* Fatture Totali */}</div>
          {sinistriAnalytics.length > 0 && (
            <PieGraph
              data={{
                labels: sinistriAnalytics.map((el) =>
                  handleStato(el.stato, false)
                ),
                datasets: [
                  {
                    label: "N° Sinistri",
                    data: sinistriAnalytics.map((el) => el.count),
                    backgroundColor: sinistriAnalytics.map((el) =>
                      handleStato(el.stato, true)
                    ),
                    borderColor: sinistriAnalytics.map((el) =>
                      handleStato(el.stato, true)
                    ),
                    borderWidth: 1,
                  },
                ],
              }}
            />
          )}
        </div>
        <div className="col-span-2 lg:col-span-1 container-box flex place-content-center h-full">
          <div className="text-lg font-semibold ">Sinistri Aperti</div>
          <div className="h-96">
            <DataGridPro
            ignoreDiacritics
              rows={sinistriAperte}
              columns={columns}
              onRowClick={(row) => {
                if (sessionStorage.getItem("admin") !== "0") {
                  navigate("/Dettaglio-Sinistro", {
                    state: { sinistro: row.row },
                  });
                }
              }}
              getRowId={(row) => row.id}
            />
          </div>
        </div>
        <div className="col-span-2 lg:col-span-1 container-box flex place-content-center h-full">
          <div className="text-lg font-semibold ">Sinistri Da Aprire</div>
          <div className="h-96">
            <DataGridPro
            ignoreDiacritics
              rows={sinistriDaAprire}
              columns={columns}
              onRowClick={(row) => {
                if (sessionStorage.getItem("admin") !== "0") {
                  navigate("/Dettaglio-Sinistro", {
                    state: { sinistro: row.row },
                  });
                }
              }}
              getRowId={(row) => row.id}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
{
  /* <Modal
        isOpen={isOpen}
        closeButton={() => setIsOpen(false)}
        headerText="Dettaglio attrezzature"
        rightButtons={
          <>
            <Button
              className="success-button"
              size="md"
              text={"Salva"}
              icon={<AiOutlineCheck className="button-icon" />}
              //onClick={handleSave}
            />
          </>
        }
      ></Modal> */
}
