import { useContext, useEffect, useState } from "react";
import {
  DataGridPro,
  GridToolbarContainer,
  GridToolbarQuickFilter,
  GRID_CHECKBOX_SELECTION_COL_DEF,
  GridLogicOperator,
  GridToolbarExport,
} from "@mui/x-data-grid-pro";
import { Skeleton, Tooltip, Typography } from "@mui/material";
import { FaCircle } from "react-icons/fa";
import {
  HiLockClosed,
  HiMiniFunnel,
  HiOutlineBellAlert,
  HiOutlineBriefcase,
  HiOutlineBuildingLibrary,
  HiOutlineBuildingOffice,
  HiOutlineCalendar,
  HiOutlineCheck,
  HiOutlineChevronDown,
  HiOutlineClipboard,
  HiOutlineCurrencyEuro,
  HiOutlineDocumentText,
  HiOutlineExclamationTriangle,
  HiOutlineFunnel,
  HiOutlineHashtag,
  HiOutlineInboxStack,
  HiOutlineLink,
  HiOutlineListBullet,
  HiOutlinePlus,
  HiOutlineQueueList,
  HiOutlineTrash,
  HiOutlineUser,
  HiOutlineUserCircle,
  HiOutlineUserGroup,
} from "react-icons/hi2";
import { useLocation, useNavigate } from "react-router-dom";
import {
  HiOutlineCash,
  HiOutlineClipboardCopy,
  HiOutlineDuplicate,
  HiOutlineExternalLink,
  HiOutlineMail,
  HiOutlinePencilAlt,
} from "react-icons/hi";
import { TabPanel } from "../components/TabPanel";
import { ComponentDenuncia } from "../components/ComponentDenuncia";
import { ComponentFattura } from "../components/ComponentFattura";
import { ComponentDocumentazione } from "../components/ComponentDocumentazione";
import { Autocomplete } from "../components/Autocomplete";
import { Modal } from "../components/Modal";
import { Input } from "../components/Input";
import { StatoSinistri } from "../components/StatoSinistri";
import { toast } from "react-toastify";
import { Amministratore } from "../utility/useAmministraotre";
import moment from "moment";
import { Select } from "../components/Select";
import { SelectId } from "../components/SelectId";
import api from "../components/api/api";
import { DataInput } from "../components/DataInput";
import { ModalDelete } from "../components/ModalDelete";
import { NuovoPerito, NuovoStudioPeritale } from "./Periti";
import { NuovoCondominio } from "./Condominii";
import { NuovaCompagnia } from "./Compagnie";
import { NuovaAgenzia } from "./Agenzie";
import { NuovaImpresa } from "./Imprese";
import { NuovaPersonaInteressata } from "./PersoneInteressate";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { ModalSM } from "../components/ModalSM";
import { Checkbox } from "../components/Checkbox";
import {
  MdOutlineFilter,
  MdOutlineTimer,
  MdOutlineTimerOff,
} from "react-icons/md";
import { stringify, v4 as uuidv4 } from "uuid";
import "moment/locale/it";
import { AiOutlineLoading3Quarters } from "react-icons/ai";

function splitString(str, chunkSize) {
  var chunks = [];
  for (var i = 0; i < str.length; i += chunkSize) {
    chunks.push(str.substring(i, i + chunkSize));
  }
  return chunks;
}

const columnsPersone = [
  {
    field: "cognome",
    headerName: "Cognome",
    width: 150,
    editable: false,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "nome",
    headerName: "Nome",
    width: 150,
    editable: false,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "telefono",
    headerName: "Telefono",
    width: 150,
    editable: false,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "email",
    headerName: "Email",
    width: 150,
    editable: false,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "piano",
    headerName: "Piano",
    width: 150,
    editable: false,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "inquilino",
    headerName: "Inquilino",
    width: 150,
    editable: false,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "riferimento",
    headerName: "Riferimento",
    width: 150,
    editable: false,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "ordine",
    headerName: "Ordine",
    width: 150,
    editable: false,
    align: "center",
    headerAlign: "center",
  },
];

const columnsImprese = [
  {
    field: "impresa",
    headerName: "Impresa",
    width: 150,
    editable: false,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "intervento",
    headerName: "Intervento",
    width: 150,
    editable: false,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "fattura",
    headerName: "Importo",
    width: 100,
    editable: false,
    align: "center",
    headerAlign: "center",
    valueFormatter: (value) => value && value + "€",
  },
  {
    field: "anno",
    headerName: "Anno",
    width: 70,
    editable: false,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "n_fattura",
    headerName: "N° Fattura",
    width: 100,
    editable: false,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "pagante",
    headerName: "Pagante",
    width: 100,
    editable: false,
    align: "center",
    headerAlign: "center",
    valueGetter: (params, row) => {
      const data = row.dettaglio_pagamento ? row.dettaglio_pagamento : "";
      return `${data}`;
    },
    renderCell: (params) => (
      <Tooltip title={params.row.dettaglio_pagamento}>
        <div className="flex h-full justify-center items-center">
          {params.row.pagante === 2 ? (
            <HiOutlineBuildingOffice className="text-2xl text-sinistri-darkgreen" />
          ) : (
            <FaCircle
              className={`text-3xl`}
              color={params.row.pagante === 1 ? "#2d8a81" : "white"}
            />
          )}
        </div>
      </Tooltip>
    ),
  },
  {
    field: "dettaglio_pagamento",
    headerName: "Dettaglio Pagamento",
    width: 150,
    editable: false,
    align: "center",
    headerAlign: "center",
  },
];

const columnsCoAssicurazione = [
  {
    field: "n_sinistro",
    headerName: "N° Sinistro",
    width: 150,
    editable: false,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "compagnia",
    headerName: "Compagnia",
    width: 150,
    editable: false,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "studio_peritale",
    headerName: "Studio Peritale",
    width: 150,
    editable: false,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "perito",
    headerName: "Perito",
    width: 150,
    editable: false,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "agenzia",
    headerName: "Agenzia",
    width: 150,
    editable: false,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "liquidazione",
    headerName: "Liquidazione",
    width: 150,
    editable: false,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "appunti",
    headerName: "appunti",
    width: 150,
    editable: false,
    align: "center",
    headerAlign: "center",
  },
];

const columnsStorico = [
  {
    field: "tipologia_mail",
    headerName: "Tipologia",
    width: 150,
    editable: false,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "data_invio",
    headerName: "Data",
    width: 150,
    editable: false,
    align: "center",
    headerAlign: "center",
    valueFormatter: (value) =>
      value && moment(value).format("DD-MM-YYYY HH:mm"),
  },
  {
    field: "destinatario",
    headerName: "destinatario",
    width: 150,
    editable: false,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "testo",
    headerName: "Testo",
    width: 150,
    editable: false,
    align: "center",
    headerAlign: "center",
    renderCell: (params) => {
      return (
        <Tooltip
          placement="left-start"
          className="cursor-pointer "
          title={<div className="text-sm">{params.row.testo}</div>}
        >
          {params.row.testo}
        </Tooltip>
      );
    },
  },
  {
    field: "utente",
    headerName: "Utenze",
    width: 150,
    editable: false,
    align: "center",
    headerAlign: "center",
  },
];

const defaultColumns = [
  {
    field: "riferimento",
    headerName: "Rif.",
    width: 70,
    editable: false,
    align: "center",
    headerAlign: "center",
    valueGetter: (params) => params,
    renderCell: (params) => {
      let color = "lightgrey";
      let tooltip = "Pratica da gestire";
      switch (params.row.stato) {
        case "grey":
          tooltip = "Chiuso";
          if (params.row.stato_pagamento === 1) {
            color = "#000000";
          } else {
            color = "#B1B3B3";
          }
          break;
        case "red":
          color = "#D3000090";
          tooltip = "In attesa di documentazione";
          break;
        case "purple":
          color = "#6600A190";
          tooltip = "Creare ed inviare denuncia";
          break;
        case "yellow":
          color = "#F6CD4C";
          tooltip = "Denuncia inviata";
          break;
        case "green":
          color = "#2d8a81";
          tooltip = "Atto da firmare inviato";
          break;
        case "lightgrey":
          color = "#fffff";
          tooltip = "Pratica da gestire";
          break;
        case "celeste":
          color = "#99cbff";
          tooltip = "Da completare";
          break;

        default:
          break;
      }

      return (
        <Tooltip title={tooltip}>
          <div
            className="w-full h-full flex items-center justify-center "
            style={{
              backgroundColor: color,
              color: color === "#fffff" ? "black" : "white",
            }}
          >
            {params.row.riferimento}/
            {params.row.data_esercizio &&
              moment(params.row.data_esercizio).format("YY")}
          </div>
        </Tooltip>
      );
    },
  },
  {
    field: "condominio",
    headerName: "Condominio",
    width: 200,
    editable: false,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "compagnia",
    headerName: "Compagnia",
    width: 120,
    editable: false,
    align: "center",
    headerAlign: "center",
    renderCell: (params) => {
      return (
        <div
          style={{
            backgroundColor: params.row.co_assicurazione === 1 ? "#2d8a81" : "",
            color: params.row.co_assicurazione === 1 ? "#ffffff" : "",
          }}
        >
          {params.row.compagnia}
        </div>
      );
    },
  },
  {
    field: "n_sinistro",
    headerName: "N° Sinistro",
    width: 100,
    editable: false,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "data",
    headerName: "Data",
    width: 100,
    editable: false,
    align: "center",
    headerAlign: "center",
    valueGetter: (params, row) => {
      const data = row?.data ? moment(row?.data).format("DD-MM-YYYY") : "";
      const dataInvio = row?.data_invio
        ? `(${moment(row?.data_invio).format("DD/MM")})`
        : "";
      const dataInvioEffettivo =
        row?.data_invio_effettivo &&
        moment(row?.data_invio_effettivo).format("DD/MM") !==
          moment(row?.data_invio).format("DD/MM")
          ? moment(row?.data_invio_effettivo).format("DD/MM")
          : "";

      // Combine the values into a single string for the quick filter
      return `${data} ${dataInvio} ${dataInvioEffettivo}`;
    },
    renderCell: (params) => (
      <>
        <Typography
          fontSize={13}
          lineHeight={1}
          className="text-center text-sx lowercase"
        >
          {params.row.data && moment(params.row.data).format("DD-MM-YYYY")}
        </Typography>
        <Typography
          fontSize={13}
          lineHeight={1}
          className="text-center text-sx lowercase"
        >
          {params.row.data_invio &&
            "(" + moment(params.row.data_invio).format("DD/MM") + ")"}
        </Typography>
        {params.row.data_invio_effettivo &&
          moment(params.row.data_invio_effettivo).format("DD/MM") !==
            moment(params.row.data_invio).format("DD/MM") && (
            <Typography
              fontSize={13}
              lineHeight={1}
              className="text-center text-sx lowercase"
            >
              {params.row.data_invio &&
                moment(params.row.data_invio_effettivo).format("DD/MM")}
            </Typography>
          )}
      </>
    ),
    //valueFormatter: (value) => value && moment(value).format("DD-MM-YYYY"),
  },
  /* {
    field: "dataDenuncia",
    headerName: "Invio Denuncia",
    width: 80,
    editable: false,
    align: "center",
    headerAlign: "center",
  }, */
  {
    field: "appunti",
    headerName: "Appunti",
    width: 200,
    editable: false,
    align: "center",
    headerAlign: "center",
    renderCell: (params) => {
      var resultArray = splitString(params.row.appunti, 32);
      return (
        <Tooltip
          placement="left-start"
          className="cursor-pointer "
          title={<div className="text-sm">{params.row.appunti}</div>}
        >
          {resultArray.map((el) => (
            <Typography
              fontSize={13}
              lineHeight={1}
              className="text-left text-sx lowercase"
            >
              {el}
            </Typography>
          ))}
        </Tooltip>
      );
    },
  },
  {
    field: "studio_peritale",
    headerName: "Perito",
    width: 100,
    editable: false,
    align: "center",
    headerAlign: "center",
    valueGetter: (params, row) => {
      const data = row?.studio_peritale ? row?.studio_peritale : "";
      const dataPerito = row?.perito ? row?.perito : "";

      // Combine the values into a single string for the quick filter
      return `${data} ${dataPerito}`;
    },
    renderCell: (params) => (
      <Tooltip
        placement="left-start"
        className="cursor-pointer "
        title={
          <>
            <div className="text-sm w-full">
              {params.row?.studio_peritale_info}
            </div>
            <div className="text-sm w-full">{params.row?.perito_info}</div>
          </>
        }
      >
        <Typography
          fontSize={14}
          lineHeight={1}
          className="text-left text-sx uppercase"
        >
          {params.row?.studio_peritale}
        </Typography>
        <Typography
          fontSize={13}
          lineHeight={1}
          className="text-left text-sx lowercase"
        >
          {params.row?.perito}
        </Typography>
      </Tooltip>
    ),
  },
  {
    field: "imprese",
    headerName: "Impresa",
    width: 100,
    editable: false,
    align: "center",
    headerAlign: "center",
    renderCell: (params) => {
      var resultArray = params.row.imprese?.split(",");
      return (
        <Tooltip
          className="cursor-pointer "
          title={<div className="text-sm">{params.row.imprese}</div>}
        >
          {resultArray?.map((el) => (
            <Typography
              fontSize={13}
              lineHeight={1}
              className="text-left text-sx uppercase"
            >
              {el}
            </Typography>
          ))}
        </Tooltip>
      );
    },
  },
  {
    field: "persone",
    headerName: "Persone Interessate",
    width: 100,
    editable: false,
    align: "center",
    headerAlign: "center",
    valueGetter: (params, row) => {
      const data = row?.danno ? row?.danno : "";
      const dataPersone = row?.persone ? row?.persone : "";

      // Combine the values into a single string for the quick filter
      return `${data} ${dataPersone}`;
    },
    renderCell: (params) => {
      var infoArray = params.row.persone_info?.split(",");
      var resultArray = params.row.persone?.split(",");
      return (
        <Tooltip
          placement="left-start"
          className="cursor-pointer "
          title={infoArray?.map((info) => (
            <div className="text-sm w-full">{info}</div>
          ))}
        >
          <Typography
            fontSize={13}
            lineHeight={1}
            className="text-left text-sx uppercase "
          >
            {params.row?.danno}
          </Typography>
          {resultArray?.map((el) => (
            <Typography
              fontSize={13}
              lineHeight={1}
              className="text-left text-sx uppercase "
            >
              {el}
            </Typography>
          ))}
        </Tooltip>
      );
    },
  },
  {
    field: "liquidazioni",
    headerName: "Liquidazioni",
    width: 150,
    editable: false,
    align: "center",
    headerAlign: "center",
    valueFormatter: (value) => value && value + "€",
  },
  {
    field: "**",
    headerName: "Franchigie",
    width: 150,
    editable: false,
    align: "center",
    headerAlign: "center",
    valueGetter: (params, row) => {
      const dataValore = row?.valore ? row?.valore + "€" : "";
      const dataDoppioValore = row?.doppio_valore
        ? row?.doppio_valore + "€"
        : "";
      const dataMinimo = row?.minimo ? row?.minimo : "";
      const dataPercentuale = row?.percentuale ? row?.percentuale + "%" : "";
      // Combine the values into a single string for the quick filter
      return `${dataValore} ${dataDoppioValore} ${dataMinimo} ${dataPercentuale}`;
    },
    renderCell: (params) => (
      <>
        {params.row.valore && params.row.valore + "€"}{" "}
        {params.row.doppio_valore && params.row.doppio_valore + "€"}{" "}
        {params.row.minimo && params.row.minimo}{" "}
        {params.row.percentuale && params.row.percentuale + "%"}
      </>
    ),
  },
  {
    field: "*",
    headerName: "Polizza",
    width: 80,
    editable: false,
    align: "center",
    headerAlign: "center",
    renderCell: (params) => {
      let color = "green";
      let tooltip = "";
      if (!params.row.scadenza_polizza) {
        color = "gray";
        tooltip = "Non presente";
      } else if (
        moment(new Date()).add(1, "M").format("YYYY-MM-DD") <
        moment(params.row.scadenza_polizza).format("YYYY-MM-DD")
      ) {
        color = "green";
        tooltip = "Aggiornata";
      } else if (
        //se scade entro 1 mese-> ARANCIONE
        moment(new Date()).add(1, "M").format("YYYY-MM-DD") >
          moment(params.row.scadenza_polizza).format("YYYY-MM-DD") &&
        moment(params.row.scadenza_polizza).format("YYYY-MM-DD") >=
          moment(new Date()).format("YYYY-MM-DD")
      ) {
        color = "orange";
        tooltip =
          "In Scadenza " +
          moment(params.row.scadenza_polizza).format("YYYY-MM-DD");
        //se scaduto-> ROSSO
      } else {
        color = "red";
        tooltip = "Scaduta";
      }
      return (
        <Tooltip title={tooltip}>
          <div className="w-full h-full flex items-center justify-center">
            <FaCircle className={`text-3xl`} color={color} />
          </div>
        </Tooltip>
      );
    },
  },
];

const findNewObject = (arr1, arr2) => {
  return arr1.filter((obj1) => !arr2.some((obj2) => obj1.id === obj2.id));
};

export const Sinistri = () => {
  const [loading, setLoading] = useState(false);
  const [loadingDuplicate, setLoadingDuplicate] = useState(false);
  const navigate = useNavigate();

  const [noDateFilter, setNoDateFilter] = useState(false);
  const [filter, setFilter] = useState(false);
  const [filterValue, setFilterValue] = useState("");

  const [columns, setColumns] = useState([]);

  const stati = [
    "red",
    "purple",
    "yellow",
    "lightgrey",
    "celeste",
    "green",
    "grey",
  ];

  const [filterDateDa, setFilterDateDa] = useState(new Date());
  const [filterDateA, setFilterDateA] = useState(new Date());

  const [openDialogDuplicate, setOpenDialogDuplicate] = useState(false);

  const [openDialogDate, setOpenDialogDate] = useState(false);
  const [date, setDate] = useState("");
  const [selectedIds, setSelectedIds] = useState([]);
  const [sinistriBackup, setSinistriBackup] = useState([]);
  const [sinistri, setSinistri] = useState([]);
  const [sinistriAnalytics, setSinistriAnalytics] = useState([]);

  const [amministratore, setAmministratore] = useContext(Amministratore);

  //EMAILS
  const [open, setOpen] = useState(false);
  const [emailType, setEmailType] = useState("");
  const [selectedSinistri, setSelectedSinistri] = useState([]);
  const [openSelect, setOpenSelect] = useState(false);

  const handleEmailType = async (type) => {
    let response = await api.get("tipologie_mail/" + type);
    let testo = response.data.data[0].testo;
    navigate("/Invio-Email", {
      state: {
        type: type,
        selectedSinistri: selectedSinistri,
        testo: testo,
      },
    });
  };

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <div className="flex flex-col lg:flex-row lg:items-center justify-between w-full mb-2 gap-2">
          <GridToolbarQuickFilter placeholder="Cerca..." id="filter" />
          <div className="flex flex-1 flex-row flex-wrap lg:flex-nowrap justify-start items-center gap-2">
            <div className="w-full lg:w-auto h-min flex items-center gap-2">
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DatePicker
                  label="Dall'anno"
                  sx={{ width: 120 }}
                  disabled={noDateFilter}
                  views={["year"]}
                  openTo="year"
                  className="w-full bg-white  hover:border-black border border-white text-black"
                  onChange={(e) => {
                    handleSinistriByYear(
                      moment(e).format("YYYY"),
                      moment(filterDateA).format("YYYY")
                    );
                    setFilterDateDa(e);
                  }}
                  value={moment(filterDateDa)}
                />
              </LocalizationProvider>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DatePicker
                  label="All'anno"
                  sx={{ width: 120 }}
                  disabled={noDateFilter}
                  views={["year"]}
                  openTo="year"
                  className="w-full bg-white  hover:border-black border border-white text-black"
                  onChange={(e) => {
                    handleSinistriByYear(
                      moment(filterDateDa).format("YYYY"),
                      moment(e).format("YYYY")
                    );
                    setFilterDateA(e);
                  }}
                  value={moment(filterDateA)}
                />
              </LocalizationProvider>
              {filter ? (
                <HiMiniFunnel
                  size={36}
                  onClick={() => {
                    sessionStorage.setItem("filter", "");
                    sessionStorage.setItem("stati", "");
                    sessionStorage.setItem("filterDateDa", "");
                    sessionStorage.setItem("filterDateA", "");
                    setFilter(false);
                    toast.success("Filtro disattivato!");
                  }}
                  className="cursor-pointer"
                />
              ) : (
                <HiOutlineFunnel
                  size={36}
                  onClick={() => {
                    sessionStorage.setItem(
                      "filter",
                      document.getElementById("filter").value
                    );
                    sessionStorage.setItem("filterDateDa", filterDateDa);
                    sessionStorage.setItem("filterDateA", filterDateA);
                    let filters = JSON.stringify(sinistriAnalytics);
                    sessionStorage.setItem("stati", filters);
                    toast.success("Filtro memorizzato!");
                    setFilter(true);
                  }}
                  className="cursor-pointer"
                />
              )}
              {!noDateFilter ? (
                <MdOutlineTimer
                  size={36}
                  onClick={handleRemoveDateFilter}
                  className="cursor-pointer"
                />
              ) : (
                <MdOutlineTimerOff
                  size={36}
                  onClick={handleRemoveDateFilter}
                  className="cursor-pointer"
                />
              )}
            </div>
            {stati?.map((sin) => {
              let color = "lightgrey";
              let tooltip = "Pratica da gestire";
              const statoSelected = sinistriAnalytics?.filter(
                (el) => el.stato === sin
              )[0];
              switch (sin) {
                case "grey":
                  tooltip = "Chiuso";
                  color = "#B1B3B3";
                  break;
                case "red":
                  color = "#D3000090";
                  tooltip = "In attesa di documentazione";
                  break;
                case "purple":
                  color = "#6600A190";
                  tooltip = "Creare ed inviare denuncia";
                  break;
                case "yellow":
                  color = "#F6CD4C";
                  tooltip = "Denuncia inviata";
                  break;
                case "green":
                  color = "#2d8a81";
                  tooltip = "Atto da firmare inviato";
                  break;
                case "lightgrey":
                  color = "#dedede";
                  tooltip = "Pratica da gestire";
                  break;
                case "celeste":
                  color = "#99cbff";
                  tooltip = "Da completare";
                  break;

                default:
                  break;
              }
              return (
                <Tooltip title={tooltip}>
                  <div
                    className="w-8 h-8 rounded-md flex justify-center items-center font-semibold cursor-pointer text-xs transition-all duration-300 hover:bg-transparent"
                    style={{
                      backgroundColor:
                        statoSelected?.filter === 1 || !statoSelected
                          ? color
                          : "",
                      color:
                        statoSelected?.filter === 1 || !statoSelected
                          ? "white"
                          : color,
                      borderColor:
                        statoSelected?.filter === 0 || !statoSelected
                          ? color
                          : "",
                      borderWidth:
                        statoSelected?.filter === 0 || !statoSelected
                          ? "2px"
                          : "0px",
                    }}
                    onClick={() => {
                      if (statoSelected) {
                        handleFilter(sinistriBackup, sinistriAnalytics, sin);
                      }
                    }}
                  >
                    {statoSelected ? statoSelected?.count : 0}
                  </div>
                </Tooltip>
              );
            })}
            <div
              className="py-1.5 px-2 rounded-md flex justify-center items-center text-sm bg-sinistri-blue text-white cursor-pointer"
              onClick={() =>
                handleFilterReset(sinistriBackup, sinistriAnalytics)
              }
            >
              Tot: {sinistriAnalytics[0]?.total_count}
            </div>
            <GridToolbarExport />
          </div>
          <div className="flex gap-2 justify-end">
            {selectedIds?.length > 0 &&
              sessionStorage.getItem("admin") !== "0" && (
                <>
                  <div
                    className="icon-container-blue group"
                    onClick={() => setOpen(true)}
                  >
                    <HiOutlineMail className="icon-blue" />
                  </div>
                  <div
                    className="icon-container-blue group"
                    onClick={() => setOpenDialogDate(true)}
                  >
                    <HiOutlineCalendar className="icon-blue" />
                  </div>
                  {selectedIds?.length === 1 && amministratore !== 0 && (
                    <div
                      className="icon-container-blue group"
                      onClick={() => setOpenDialogDuplicate(true)}
                    >
                      <HiOutlineDuplicate className="icon-blue" />
                    </div>
                  )}
                </>
              )}
            {sessionStorage.getItem("admin") !== "0" &&
              sessionStorage.getItem("amministratore") !== "0" && (
                <div
                  className="icon-container-green group "
                  onClick={() => {
                    let currenYear = sinistriBackup?.filter(
                      (sin) =>
                        moment(sin.data_esercizio).format("YYYY") ===
                        moment(new Date()).format("YYYY")
                    );
                    let rif =
                      currenYear.length > 0
                        ? Math.max(...currenYear?.map((o) => o.riferimento))
                        : 0;
                    navigate("/Nuovo-Sinistro", {
                      state: {
                        riferimento: rif,
                        sinistriBk: sinistriBackup,
                        sinistri: sinistriBackup
                          .filter((sin) => sin.riferimento)
                          .map((el) => el.riferimento),
                      },
                    });
                  }}
                >
                  <HiOutlinePlus className="icon-green" />
                </div>
              )}
          </div>
        </div>
      </GridToolbarContainer>
    );
  }

  function filterStato(objects, states) {
    return objects.filter((object) => states.includes(object.stato));
  }

  function handleFilter(sin, filtri, filter) {
    setLoading(true);
    const order = ["red", "purple", "yellow", "lightgrey", "green", "grey"];
    const sinBackup = [...sin];
    const filtriBackup = filtri?.map((el) => {
      let sinistro = { ...el };
      if (el.stato === filter) {
        return { ...sinistro, filter: el.filter === 0 ? 1 : 0 };
      } else {
        return sinistro;
      }
    });
    const toFilter = filtriBackup
      ?.filter((el) => el.filter === 1)
      .map((s) => s.stato);

    //ordino la posizione dei filtri in base allo stato
    setSinistriAnalytics(
      filtriBackup.sort(
        (a, b) => order.indexOf(a?.stato) - order.indexOf(b?.stato)
      )
    );
    const filteredSinistri = filterStato(sinBackup, toFilter);

    setSinistri(filteredSinistri);
    setLoading(false);
  }

  function handleFilterReset(sin, filtri) {
    setLoading(true);
    const order = [
      "red",
      "purple",
      "yellow",
      "lightgrey",
      "celeste",
      "green",
      "grey",
    ];
    const sinBackup = [...sin];
    //imposto tutti i filtri arrivi
    const filtriBackup = filtri?.map((el) => {
      let sinistro = { ...el };
      return { ...sinistro, filter: 1 };
    });
    const toFilter = filtriBackup
      ?.filter((el) => el.filter === 1)
      .map((s) => s.stato);

    //ordino la posizione dei filtri in base allo stato
    setSinistriAnalytics(
      filtriBackup.sort(
        (a, b) => order.indexOf(a?.stato) - order.indexOf(b?.stato)
      )
    );
    const filteredSinistri = filterStato(sinBackup, toFilter);

    setSinistri(filteredSinistri);
    setLoading(false);
  }

  const handleDuplicate = async () => {
    setLoadingDuplicate(true);
    const sinistroSelezionato = selectedSinistri[0];
    const riferimenti = await api.get("sinistri/riferimento/" + amministratore);
    const rif = riferimenti.data.data?.filter(
      (r) =>
        r?.data_esercizio !== null &&
        moment(r.data_esercizio).format("YYYY") ===
          moment(new Date()).format("YYYY")
    );

    let annotazioni = `Rif. ${sinistroSelezionato?.riferimento}, Sin. ${sinistroSelezionato?.n_sinistro}
      ${sinistroSelezionato?.appunti}`;
    try {
      let resultSinistro = await api.post("sinistri", {
        duplica: sinistroSelezionato?.id,
        sinistro: {
          id_amministratore: amministratore,
          stato: "red",
          riferimento:
            rif.length > 0 ? Math.max(...rif.map((o) => o.riferimento)) + 1 : 1,
          id_condominio: sinistroSelezionato?.id_condominio,
          condominio: sinistroSelezionato?.condominio,
          co_assicurazione: sinistroSelezionato?.co_assicurazione,
          mail_incarico: sinistroSelezionato?.mail_incarico,
          danno_condominiale: sinistroSelezionato?.danno_condominiale,
          ol_scheda: sinistroSelezionato?.ol_scheda,
          appunti: annotazioni,
          danno: sinistroSelezionato?.danno,
          riferimento_danno: sinistroSelezionato?.riferimento_danno,
          data: moment(new Date()).format("YYYY-MM-DD"),
          data_esercizio: moment(new Date()).format("YYYY-MM-DD"),
          utente: sessionStorage.getItem("Username"),
        },
      });
      toast.success("Duplicazione avvenuta con successo!");
      sessionStorage.removeItem("modified");
      if (sessionStorage.getItem("admin") !== "0") {
        let responseSinistri = await api.get(`sinistri`, {
          params: { idSinistro: resultSinistro.data?.newId },
        });
        navigate("/Dettaglio-Sinistro", {
          state: {
            sinistro: responseSinistri.data.data[0],
            sinistri: sinistriBackup
              .filter(
                (sin) =>
                  sin.riferimento && sin.id !== resultSinistro.data?.newId
              )
              .map((el) => el.riferimento),
          },
        });
      }
    } catch (error) {
      toast.error("Errore durante la duplicazione. Riprova!");
    }
    setLoadingDuplicate(false);
  };
  const handleRemoveDateFilter = async () => {
    setNoDateFilter(!noDateFilter);
    setLoading(true);
    let responseSinistri;
    let responseSinistriAnalytics;
    if (!noDateFilter) {
      if (amministratore !== 0) {
        const urlParams = new URLSearchParams();
        urlParams.append("noYear", true);
        urlParams.append("id", amministratore);
        responseSinistri = await api.get("sinistri", { params: urlParams });
        responseSinistriAnalytics = await api.get("sinistri/analytics", {
          params: urlParams,
        });
      } else {
        const urlParams = new URLSearchParams();
        urlParams.append("noYear", true);
        responseSinistri = await api.get("sinistri/all", { params: urlParams });
        responseSinistriAnalytics = await api.get("sinistri/analytics/all", {
          params: urlParams,
        });
      }
      setSinistriBackup(responseSinistri.data.data);
      handleFilter(
        responseSinistri.data?.data,
        responseSinistriAnalytics.data?.data
      );
    } else {
      handleSinistriByYear(new Date());
    }
    setLoading(false);
  };
  const handleChangeAllDate = async () => {
    try {
      let response = await api.put("sinistri/date", {
        ids: selectedIds,
        data: moment(date).format("YYYY-MM-DD"),
      });
      toast.success(`Date aggiornate ${response.data.affectedRows}!`);
      handleSinistriByYear(
        moment(filterDateDa).format("YYYY"),
        moment(filterDateA).format("YYYY")
      );
    } catch (error) {
      toast.error("Errore durante la modifica. Riprova!");
    }
    handleCloseDialogDate();
  };

  const handleCloseDialogDate = () => {
    setSelectedIds([]);
    setDate("");
    setOpenDialogDate(false);
  };

  const handleSinistriByYear = async (annoDa, annoA) => {
    setLoading(true);
    let responseSinistri;
    let responseSinistriAnalytics;
    if (amministratore !== 0) {
      const urlParams = new URLSearchParams();
      urlParams.append("id", amministratore);
      urlParams.append("da", moment(annoDa).format("YYYY"));
      urlParams.append("a", moment(annoA).format("YYYY"));
      responseSinistri = await api.get(`sinistri`, { params: urlParams });
      responseSinistriAnalytics = await api.get("sinistri/analytics", {
        params: urlParams,
      });
    } else {
      const urlParams = new URLSearchParams();
      urlParams.append("da", moment(annoDa).format("YYYY"));
      urlParams.append("a", moment(annoA).format("YYYY"));
      responseSinistri = await api.get("sinistri/all", { params: urlParams });
      responseSinistriAnalytics = await api.get("sinistri/analytics/all", {
        params: urlParams,
      });
    }

    setSinistriBackup(responseSinistri.data.data);
    if (
      sessionStorage.getItem("stati") !== "" &&
      sessionStorage.getItem("stati")
    ) {
      const filterStati = sessionStorage.getItem("stati");
      handleFilter(responseSinistri.data?.data, JSON.parse(filterStati));
    } else {
      handleFilter(
        responseSinistri.data?.data,
        responseSinistriAnalytics.data.data
      );
    }
    setLoading(false);
  };

  useEffect(() => {
    (async () => {
      if (
        sessionStorage.getItem("filter") !== "" &&
        sessionStorage.getItem("filter")
      ) {
        setFilter(true);
      } else {
        setFilter(false);
      }
      if (
        sessionStorage.getItem("stati") !== "" &&
        sessionStorage.getItem("stati")
      ) {
        setFilter(true);
      } else {
        setFilter(false);
      }
      if (
        (sessionStorage.getItem("filterDateDa") !== "" &&
          sessionStorage.getItem("filterDateDa")) ||
        (sessionStorage.getItem("filterDateA") !== "" &&
          sessionStorage.getItem("filterDateA"))
      ) {
        setFilter(true);
        handleSinistriByYear(
          sessionStorage.getItem("filterDateDa"),
          sessionStorage.getItem("filterDateA")
        );
        setFilterDateDa(sessionStorage.getItem("filterDateDa"));
        setFilterDateA(sessionStorage.getItem("filterDateA"));
      } else {
        setFilter(false);
        handleSinistriByYear(new Date());
      }
      if (amministratore !== 0) {
        if (sessionStorage.getItem("ol_scheda") === "1") {
          setColumns([
            ...defaultColumns,
            {
              field: "ol_scheda",
              headerName: "OL/Schede",
              width: 120,
              editable: false,
              align: "center",
              headerAlign: "center",
            },
            sessionStorage.getItem("admin") === "2" && {
              field: "pagante",
              headerName: "Pagante",
              width: 150,
              editable: false,
              align: "center",
              headerAlign: "center",
              valueGetter: (params, row) => {
                var pag = row.pagante?.split(",");
                const dataImpresa = pag?.length > 0 && pag[0] ? pag[0] : "";
                const dataFattura = pag?.length > 1 && pag[1] ? pag[1] : "";
                return `${dataImpresa} ${dataFattura}`;
              },
              renderCell: (params) => {
                var pag = params.row.pagante?.split(",");
                let color = "#ffffff";
                let text = "#000000";
                if (params.row.stato_pagamento === 1) {
                  text = "#ffffff";
                  color = "#2d8a81";
                } else if (params.row.stato_pagamento === 0) {
                  text = "#ffffff";
                  color = "#D3000090";
                }
                //SE è IMPRESA MANUALE
                if (params.row.stato_pagante === 4) {
                  text = "#000000";
                  color = "#ffffff";
                }
                return (
                  <div
                    className="w-full h-full"
                    style={{
                      backgroundColor: color,
                      color: text,
                    }}
                  >
                    {pag?.length && (
                      <>
                        <Typography
                          fontSize={13}
                          lineHeight={1}
                          className="text-center text-sx uppercase "
                        >
                          {pag[0]}
                        </Typography>
                        <Typography
                          fontSize={13}
                          lineHeight={1}
                          className="text-center text-sx uppercase "
                        >
                          {pag[1]}
                        </Typography>
                      </>
                    )}
                  </div>
                );
              },
            },
            sessionStorage.getItem("admin") === "2" && {
              field: "utente",
              headerName: "Utente",
              width: 150,
              editable: false,
              align: "center",
              headerAlign: "center",
            },
            sessionStorage.getItem("admin") === "2" && {
              field: "*****",
              headerName: "Ultimi Invii",
              width: 200,
              editable: false,
              align: "center",
              headerAlign: "center",
              renderCell: (params) => (
                <Tooltip
                  placement="left-start"
                  title={
                    <>
                      <div className="w-full text-sm">
                        {params.row.data_email
                          ? "Data: " +
                            moment(params.row.data_email).format(
                              "DD-MM-YYYY HH:mm"
                            )
                          : ""}
                      </div>
                      <div className="w-full text-sm">
                        {params.row.tipologia_mail
                          ? "Tipologia: " + params.row.tipologia_mail
                          : ""}
                      </div>
                      <div className="w-full text-sm">
                        {params.row.destinatario
                          ? "Destinatario: " + params.row.destinatario
                          : ""}
                      </div>
                      <div className="w-full text-sm">{params.row.testo}</div>
                    </>
                  }
                >
                  {params.row.data_email
                    ? moment(params.row.data_email).format("DD-MM-YYYY HH:mm")
                    : ""}{" "}
                  {params.row.tipologia_mail} - {params.row.destinatario}
                </Tooltip>
              ),
            },
            sessionStorage.getItem("admin") === "2" && {
              field: "ultima_modifica",
              headerName: "Ulitma Modifica",
              width: 150,
              editable: false,
              align: "center",
              headerAlign: "center",
              renderCell: (params) =>
                params.row?.ultima_modifica
                  ? moment(params.row.ultima_modifica).format(
                      "DD-MM-YYYY HH:mm"
                    )
                  : "",
            },
          ]);
        } else {
          setColumns([
            ...defaultColumns,
            sessionStorage.getItem("admin") === "2" && {
              field: "pagante",
              headerName: "Pagante",
              width: 150,
              editable: false,
              align: "center",
              headerAlign: "center",
              valueGetter: (params, row) => {
                var pag = row.pagante?.split(",");
                const dataImpresa = pag?.length > 0 && pag[0] ? pag[0] : "";
                const dataFattura = pag?.length > 1 && pag[1] ? pag[1] : "";
                return `${dataImpresa} ${dataFattura}`;
              },
              renderCell: (params) => {
                var pag = params.row.pagante?.split(",");
                let color = "#ffffff";
                let text = "#000000";
                if (params.row.stato_pagamento === 1) {
                  text = "#ffffff";
                  color = "#2d8a81";
                } else if (params.row.stato_pagamento === 0) {
                  text = "#ffffff";
                  color = "#D3000090";
                }
                //SE è IMPRESA MANUALE
                if (params.row.stato_pagante === 4) {
                  text = "#000000";
                  color = "#ffffff";
                }
                return (
                  <div
                    className="w-full h-full"
                    style={{
                      backgroundColor: color,
                      color: text,
                    }}
                  >
                    {pag?.length && (
                      <>
                        <Typography
                          fontSize={13}
                          lineHeight={1}
                          className="text-center text-sx uppercase "
                        >
                          {pag[0]}
                        </Typography>
                        <Typography
                          fontSize={13}
                          lineHeight={1}
                          className="text-center text-sx uppercase "
                        >
                          {pag[1]}
                        </Typography>
                      </>
                    )}
                  </div>
                );
              },
            },
            sessionStorage.getItem("admin") === "2" && {
              field: "utente",
              headerName: "Utente",
              width: 150,
              editable: false,
              align: "center",
              headerAlign: "center",
            },
            sessionStorage.getItem("admin") === "2" && {
              field: "*****",
              headerName: "Ultimi Invii",
              width: 200,
              editable: false,
              align: "center",
              headerAlign: "center",
              renderCell: (params) => (
                <Tooltip
                  placement="left-start"
                  title={
                    <>
                      <div className="w-full text-sm">
                        {params.row.data_email
                          ? "Data: " +
                            moment(params.row.data_email).format(
                              "DD-MM-YYYY HH:mm"
                            )
                          : ""}
                      </div>
                      <div className="w-full text-sm">
                        {params.row.tipologia_mail
                          ? "Tipologia: " + params.row.tipologia_mail
                          : ""}
                      </div>
                      <div className="w-full text-sm">
                        {params.row.destinatario
                          ? "Destinatario: " + params.row.destinatario
                          : ""}
                      </div>
                      <div className="w-full text-sm">{params.row.testo}</div>
                    </>
                  }
                >
                  {params.row.data_email
                    ? moment(params.row.data_email).format("DD-MM-YYYY HH:mm")
                    : ""}{" "}
                  {params.row.tipologia_mail} - {params.row.destinatario}
                </Tooltip>
              ),
            },
            sessionStorage.getItem("admin") === "2" && {
              field: "ultima_modifica",
              headerName: "Ulitma Modifica",
              width: 150,
              editable: false,
              align: "center",
              headerAlign: "center",
              renderCell: (params) =>
                params.row?.ultima_modifica
                  ? moment(params.row.ultima_modifica).format(
                      "DD-MM-YYYY HH:mm"
                    )
                  : "",
            },
          ]);
        }
      } else {
        setColumns([
          {
            field: "amministratore",
            headerName: "Amministratore",
            width: 150,
            editable: false,
            align: "center",
            headerAlign: "center",
          },
          {
            field: "ol_scheda",
            headerName: "OL/Schede",
            width: 120,
            editable: false,
            align: "center",
            headerAlign: "center",
          },
          ...defaultColumns,
          sessionStorage.getItem("admin") === "2" && {
            field: "pagante",
            headerName: "Pagante",
            width: 150,
            editable: false,
            align: "center",
            headerAlign: "center",
            valueGetter: (params, row) => {
              var pag = row.pagante?.split(",");
              const dataImpresa = pag?.length > 0 && pag[0] ? pag[0] : "";
              const dataFattura = pag?.length > 1 && pag[1] ? pag[1] : "";
              return `${dataImpresa} ${dataFattura}`;
            },
            renderCell: (params) => {
              var pag = params.row.pagante?.split(",");
              let color = "#ffffff";
              let text = "#000000";
              if (params.row.stato_pagamento === 1) {
                text = "#ffffff";
                color = "#2d8a81";
              } else if (params.row.stato_pagamento === 0) {
                text = "#ffffff";
                color = "#D3000090";
              }
              //SE è IMPRESA MANUALE
              if (params.row.stato_pagante === 4) {
                text = "#000000";
                color = "#ffffff";
              }
              return (
                <div
                  className="w-full h-full"
                  style={{
                    backgroundColor: color,
                    color: text,
                  }}
                >
                  {pag?.length && (
                    <>
                      <Typography
                        fontSize={13}
                        lineHeight={1}
                        className="text-center text-sx uppercase "
                      >
                        {pag[0]}
                      </Typography>
                      <Typography
                        fontSize={13}
                        lineHeight={1}
                        className="text-center text-sx uppercase "
                      >
                        {pag[1]}
                      </Typography>
                    </>
                  )}
                </div>
              );
            },
          },
          sessionStorage.getItem("admin") === "2" && {
            field: "utente",
            headerName: "Utente",
            width: 150,
            editable: false,
            align: "center",
            headerAlign: "center",
          },
          sessionStorage.getItem("admin") === "2" && {
            field: "*****",
            headerName: "Ultimi Invii",
            width: 200,
            editable: false,
            align: "center",
            headerAlign: "center",
            renderCell: (params) => (
              <Tooltip
                placement="left-start"
                title={
                  <>
                    <div className="w-full text-sm">
                      {params.row.data_email
                        ? "Data: " +
                          moment(params.row.data_email).format(
                            "DD-MM-YYYY HH:mm"
                          )
                        : ""}
                    </div>
                    <div className="w-full text-sm">
                      {params.row.tipologia_mail
                        ? "Tipologia: " + params.row.tipologia_mail
                        : ""}
                    </div>
                    <div className="w-full text-sm">
                      {params.row.destinatario
                        ? "Destinatario: " + params.row.destinatario
                        : ""}
                    </div>
                    <div className="w-full text-sm">{params.row.testo}</div>
                  </>
                }
              >
                {params.row.data_email
                  ? moment(params.row.data_email).format("DD-MM-YYYY HH:mm")
                  : ""}{" "}
                {params.row.tipologia_mail} - {params.row.destinatario}
              </Tooltip>
            ),
          },
        ]);
      }
    })();
  }, [amministratore]);

  return (
    <>
      <div className="page-container ">
        <div className="w-full h-full">
          {!loading ? (
            <div className="h-[90vh]">
              <DataGridPro
                rows={sinistri}
                columns={columns}
                getRowId={(row) => row.id}
                initialState={{
                  filter: {
                    filterModel: {
                      items: [],
                      quickFilterValues: [
                        filter ? sessionStorage.getItem("filter") : "",
                      ],
                    },
                  },
                }}
                rowHeight={44}
                pinnedColumns={{
                  left: [
                    GRID_CHECKBOX_SELECTION_COL_DEF.field,
                    "stato",
                    "riferimento",
                    "ol_scheda",
                    "condominio",
                    "amministratore",
                  ],
                }}
                slots={{ toolbar: CustomToolbar }}
                ignoreDiacritics
                onRowClick={(row) => {
                  if (sessionStorage.getItem("admin") !== "0") {
                    navigate("/Dettaglio-Sinistro", {
                      state: {
                        sinistro: row.row,
                        sinistri: sinistriBackup
                          .filter(
                            (sin) => sin.riferimento && sin.id !== row.row.id
                          )
                          .map((el) => el.riferimento),
                      },
                    });
                  }
                }}
                disableRowSelectionOnClick
                checkboxSelection
                rowSelectionModel={selectedIds}
                onRowSelectionModelChange={(ids) => {
                  const selectedIDs = new Set(ids);
                  setSelectedIds(ids);
                  const selectedRowData = sinistri.filter((row) =>
                    selectedIDs.has(row.id)
                  );
                  setSelectedSinistri(selectedRowData);
                }}
              />
            </div>
          ) : (
            <div className="flex flex-wrap gap-2">
              <div className="flex w-full gap-2">
                <Skeleton variant="rounded" width={70} height={70} />
                <Skeleton variant="rounded" width="90%" height={70} />
              </div>
              <div className="flex w-full gap-2">
                <Skeleton variant="rounded" width={70} height={70} />
                <Skeleton variant="rounded" width="90%" height={70} />
              </div>
              <div className="flex w-full gap-2">
                <Skeleton variant="rounded" width={70} height={70} />
                <Skeleton variant="rounded" width="90%" height={70} />
              </div>
              <div className="flex w-full gap-2">
                <Skeleton variant="rounded" width={70} height={70} />
                <Skeleton variant="rounded" width="90%" height={70} />
              </div>
              <div className="flex w-full gap-2">
                <Skeleton variant="rounded" width={70} height={70} />
                <Skeleton variant="rounded" width="90%" height={70} />
              </div>
              <div className="flex w-full gap-2">
                <Skeleton variant="rounded" width={70} height={70} />
                <Skeleton variant="rounded" width="90%" height={70} />
              </div>
            </div>
          )}
        </div>
        <ModalSM
          open={openDialogDuplicate}
          handleClose={() => setOpenDialogDuplicate(false)}
        >
          <div className="w-full flex flex-col">
            <div className="text-center text-2xl">Duplicazione Sinistro</div>
            <div className="test-base mt-3">
              Sei sucuro di voler duplicare il sinistro:{" "}
              <b>{`${selectedSinistri[0]?.riferimento}/${moment(
                selectedSinistri[0]?.data_esercizio
              ).format("YY")} ${selectedSinistri[0]?.condominio}`}</b>
              ?
            </div>
            <div className="col-span-2 flex gap-4 justify-end flex-1 mt-2">
              {!loadingDuplicate ? (
                <div
                  className="icon-container-green group "
                  onClick={handleDuplicate}
                >
                  <HiOutlineCheck className="icon-green" />
                </div>
              ) : (
                <div
                  className="icon-container-green group "
                  onClick={() => toast.warning("Duplicazione in corso!")}
                >
                  <AiOutlineLoading3Quarters className="icon-green animate-spin" />
                </div>
              )}
            </div>
          </div>
        </ModalSM>
        <Modal open={openDialogDate} handleClose={handleCloseDialogDate}>
          <div className="w-full flex flex-col">
            <div className="text-center text-2xl">
              Aggiornamento Massivo Data
            </div>
            <DataInput
              label={"Data"}
              name="data"
              onChange={(e) => {
                setDate(moment(e).format("YYYY-MM-DD"));
              }}
              value={moment(date)}
            />
            {/* <span className="text-lg font-normal">Data</span>
            <div className="relative mb-4">
              <span className="absolute left-2 flex items-center pr-2 h-full">
                <HiOutlineCalendar className="text-2xl" />
              </span>

              <input
                type="date"
                name="data"
                className="w-full py-4 pl-10 pr-5 bg-white border-gray-400 hover:border-black border rounded-md placeholder:text-black"
                placeholder="Inserisci Data"
                onChange={(e) => setDate(e.target.value)}
                value={date}
              />
            </div> */}
            <div className="col-span-2 flex gap-4 justify-end flex-1 mt-4">
              <div
                className="icon-container-green group "
                onClick={handleChangeAllDate}
              >
                <HiOutlineCheck className="icon-green" />
              </div>
            </div>
          </div>
        </Modal>
        <ModalSM open={open} handleClose={() => setOpen(false)}>
          <div className={`col-span-2 duration-300 ${openSelect && "h-96"}`}>
            <span className=" text-lg font-normal">Tipologia Mail</span>
            <div
              className="w-full flex space-x-4 items-center p-4 bg-white border-gray-400 hover:border-black border rounded-md cursor-pointer relative"
              onClick={() => setOpenSelect(!openSelect)}
            >
              <div className="w-full flex-1">
                {!emailType ? "Seleziona Tipologia Email" : emailType}
              </div>
              <HiOutlineChevronDown
                className={`ablsolute right-0 text-2xl duration-300 ${
                  openSelect && "-rotate-180"
                }`}
              />
              <div
                className={`py-4 absolute top-[58px] -left-4 flex flex-col gap-0 bg-gray-100 border overflow-y-auto w-full rounded-md shadow-md ${
                  openSelect ? "opacity-100 z-50" : "opacity-0 z-0 hidden"
                }`}
              >
                <div class="relative flex items-center justify-center w-full">
                  <hr class="w-full border-t border-gray-300" />
                  <span class="absolute px-2 bg-gray-100 text-gray-600">
                    GENERICHE
                  </span>
                </div>
                <div
                  className="flex py-2 px-4 gap-4 hover:bg-white"
                  onClick={() => handleEmailType("Amministrazione")}
                >
                  Amministrazione
                </div>
                <div
                  className="flex py-2 pb-4 px-4 gap-4 hover:bg-white"
                  onClick={() => handleEmailType("Pratica")}
                >
                  Pratica(perito, agenzia, danneggiato)
                </div>
                <div class="relative flex items-center justify-center w-full">
                  <hr class="w-full border-t border-gray-300" />
                  <span class="absolute px-2 bg-gray-100 text-gray-600">
                    SOLLECITI
                  </span>
                </div>
                {/* <div
                  className="flex py-2 px-4 gap-4 hover:bg-white"
                  onClick={() => handleEmailType("Documentazione")}
                >
                  Documentazione
                </div> */}
                <div
                  className="flex py-2 px-4 gap-4 hover:bg-white"
                  onClick={() => handleEmailType("Sinistro")}
                >
                  Sinistro
                </div>
                <div
                  className="flex py-2 px-4 gap-4 hover:bg-white"
                  onClick={() => handleEmailType("Invio Atto")}
                >
                  Invio Atto
                </div>
                <div
                  className="flex py-2 px-4 gap-4 hover:bg-white"
                  onClick={() => handleEmailType("Sollecito Atto Firmato")}
                >
                  Sollecito Atto Firmato
                </div>
                <div
                  className="flex py-2 px-4 gap-4 hover:bg-white"
                  onClick={() => handleEmailType("Invio Proposta")}
                >
                  Invio Proposta
                </div>
              </div>
            </div>
          </div>
          {/* <EmailMaker selectedSinistri={selectedSinistri}/> */}
        </ModalSM>
      </div>
    </>
  );
};

export const NuovaPartica = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [openCoAssicurazione, setOpenCoAssicurazione] = useState(false);

  const [openNewPersona, setOpenNewPersona] = useState(false);

  const [openPersone, setOpenPersone] = useState(false);
  const [personaInteressata, setPersonaInteressata] = useState({
    id_persona_interessata: "",
    ordine: 0,
  });

  const [openNewCondominio, setOpenNewCondominio] = useState(false);
  const [openNewStudio, setOpenNewStudio] = useState(false);
  const [openNewPerito, setOpenNewPerito] = useState(false);

  const [openImpresa, setOpenImpresa] = useState(false);

  const [amministratore, setAmministratore] = useContext(Amministratore);
  const [condominii, setCondominii] = useState([]);
  const [compagnie, setCompagnie] = useState([]);
  const [agenzie, setAgenzie] = useState([]);
  const [tipologieSinistri, setTipologieSinistri] = useState([]);
  const [periti, setPeriti] = useState([]);
  const [studiPeritali, setStudiPeritali] = useState([]);

  const [relPersoneInteressate, setRelPersoneInteressate] = useState([]);
  const [personeInteressate, setPersoneInteressate] = useState([]);

  const [relImprese, setRelImprese] = useState([]);
  const [imprese, setImprese] = useState([]);

  const [relCoAss, setRelCoAss] = useState([]);

  const [sinistro, setSinistro] = useState({
    id_amministratore: amministratore,
    riferimento: location.state?.riferimento + 1,
    id_tipologia_sinistro: null,
    stato: "red",
    id_condominio: null,
    condominio: "",
    id_compagnia_assicurativa: null,
    id_agenzia: null,
    data: new Date(),
    data_esercizio: new Date(),
    appunti: "",
    alert: "",
    id_impresa: null,
    id_perito: null,
    id_studio_peritale: null,
    n_sinistro: "",
    co_assicurazione: 0,
    danno_condominiale: 0,
    pagamento_condominiale: 0,
    danno: "",
    riferimento_danno: "",
    id_sinistro_collegato: null,
    ol_scheda: "",
    mail_incarico: "",
    custode: "",
  });

  const setSessionModified = () => {
    if (sessionStorage.getItem("modified") !== "1") {
      sessionStorage.setItem("modified", "1");
    }
  };
  const handleChange = (e) => {
    setSessionModified();
    setSinistro({ ...sinistro, [e.target.name]: e.target.value });
  };

  const handleCheckbox = (e) => {
    setSessionModified();
    setSinistro({
      ...sinistro,
      [e.target.name]: sinistro[e.target.name] === 1 ? 0 : 1,
    });
  };

  const handleCloseCoAss = async () => {
    let responseCoAss = await api.get(
      "compagnie_assicurative/sinistro/" + sinistro?.id
    );
    setRelCoAss(responseCoAss.data.data);
    //setImprese(imprese.filter((impresa) => impresa.id !== impresa.id_impresa));
    setOpenCoAssicurazione(false);
  };

  const handleCloseImpresa = async () => {
    let responseImpresa = await api.get("imprese/sinistro/" + sinistro?.id);
    setRelImprese(responseImpresa.data.data);
    //setImprese(imprese.filter((impresa) => impresa.id !== impresa.id_impresa));
    setOpenImpresa(false);
  };

  const getPersoneInteressate = async () => {
    /* PERSONE INTERESSATE */
    let responseRelPersone = await api.get(
      "persone_interessate/sinistro/" + sinistro.id
    );
    setRelPersoneInteressate(responseRelPersone.data.data);
    let responsePersone = await api.get(
      "persone_interessate/condominio/" + sinistro?.id_condominio
    );
    const differencePersoneInteressate = responsePersone.data.data?.filter(
      (persona) =>
        responseRelPersone.data.data
          ?.map((relPersona) => relPersona.id_persona_interessata)
          .indexOf(persona.id) === -1
    );
    setPersoneInteressate(differencePersoneInteressate);
  };

  const handleSavePersona = async () => {
    try {
      await api.post("persone_interessate/rel", {
        persona_interessata: {
          ...personaInteressata,
          id_sinistro: sinistro?.id,
        },
      });
      toast.success("Salvataggio completato!");
      setPersonaInteressata({
        id_persona_interessata: "",
        ordine: personaInteressata.ordine + 1,
      });
      handleClosePersone();
    } catch (error) {
      toast.error("Errore durante il salvataggio. Riprova!");
    }
  };
  const handleClosePersone = async () => {
    let responsePersone = await api.get(
      "persone_interessate/sinistro/" + sinistro?.id
    );
    setRelPersoneInteressate(responsePersone.data.data);
    setPersoneInteressate(
      personeInteressate.filter(
        (persona) => persona.id !== personaInteressata.id_persona_interessata
      )
    );
    setOpenPersone(false);
  };

  const handleSave = async () => {
    const riferimenti = await api.get("sinistri/riferimento/" + amministratore);
    const rif = riferimenti.data.data
      ?.filter((rif) => rif?.data_esercizio !== null)
      ?.map(
        (riferimento) =>
          riferimento.riferimento +
          moment(riferimento?.data_esercizio).format("YY")
      );
    if (
      rif.includes(
        sinistro.riferimento + moment(sinistro?.data_esercizio).format("YY")
      )
    ) {
      toast.error("Numero riferimento già esistente!");
    } else if (!sinistro?.id_condominio) {
      toast.error("Condominio obbligatorio!");
    } else if (sinistro?.id) {
      try {
        await api.put("sinistri", {
          sinistro: {
            ...sinistro,
            data: sinistro?.data
              ? moment(sinistro?.data).format("YYYY-MM-DD")
              : null,
            data_esercizio: sinistro?.data_esercizio
              ? moment(sinistro?.data_esercizio).format("YYYY-MM-DD")
              : null,
            utente: sessionStorage.getItem("Username"),
          },
        });
        toast.success("Modifica completata!");
        sessionStorage.removeItem("modified");
      } catch (error) {
        toast.error("Errore durante la modifica. Riprova!");
      }
    } else {
      try {
        let resultSinistro = await api.post("sinistri", {
          sinistro: {
            ...sinistro,
            data: sinistro?.data
              ? moment(sinistro?.data).format("YYYY-MM-DD")
              : null,
            data_esercizio: sinistro?.data_esercizio
              ? moment(sinistro?.data_esercizio).format("YYYY-MM-DD")
              : null,
            utente: sessionStorage.getItem("Username"),
          },
        });
        toast.success("Salvataggio completato!");
        sessionStorage.removeItem("modified");
        setSinistro({ ...sinistro, id: resultSinistro.data?.newId });
        if (sessionStorage.getItem("admin") !== "0") {
          let responseSinistri = await api.get(`sinistri`, {
            params: { idSinistro: resultSinistro.data?.newId },
          });
          navigate("/Dettaglio-Sinistro", {
            state: {
              sinistro: responseSinistri.data.data[0],
              sinistri: location.state?.sinistriBk
                .filter(
                  (sin) =>
                    sin.riferimento && sin.id !== resultSinistro.data?.newId
                )
                .map((el) => el.riferimento),
            },
          });
        }
        /* let responsePersone = await api.get(
          "persone_interessate/condominio/" + sinistro?.id_condominio
        );
        setPersoneInteressate(responsePersone.data.data);
        let responseImprese;
        responseImprese = await api.get("imprese");
        setImprese(responseImprese.data.data); */
      } catch (error) {
        toast.error("Errore durante il salvataggio. Riprova!");
      }
    }
  };

  function CustomToolbarCoAssicurazione() {
    return (
      <GridToolbarContainer>
        <div className="flex items-center justify-between w-full mb-2">
          {/* <GridToolbarQuickFilter placeholder="Cerca..." /> */}
          <div></div>
          <div className="flex gap-2">
            <div
              className="icon-container-green group "
              onClick={() => setOpenCoAssicurazione(true)}
            >
              <HiOutlinePlus className="icon-green" />
            </div>
          </div>
        </div>
      </GridToolbarContainer>
    );
  }
  function CustomToolbarImprese() {
    return (
      <GridToolbarContainer>
        <div className="flex items-center justify-between w-full mb-2">
          <div className="col-span-2 text-center text-2xl">Imprese</div>
          <div className="flex gap-2">
            <div
              className="icon-container-green group "
              onClick={() => setOpenImpresa(true)}
            >
              <HiOutlinePlus className="icon-green" />
            </div>
          </div>
        </div>
      </GridToolbarContainer>
    );
  }
  function CustomToolbarPersone() {
    return (
      <GridToolbarContainer>
        <div className="flex items-center justify-between w-full mb-2">
          {/* <GridToolbarQuickFilter placeholder="Cerca..." /> */}
          <div className="col-span-2 text-center text-2xl">
            Persone Interessate
          </div>
          <div className="flex gap-2">
            <div
              className="icon-container-green group "
              onClick={() => {
                setOpenPersone(true);
              }}
            >
              <HiOutlinePlus className="icon-green" />
            </div>
          </div>
        </div>
      </GridToolbarContainer>
    );
  }

  useEffect(() => {
    const preventReload = (event) => {
      // Prevent default behavior for F5 and Ctrl+R
      if (event.key === "F5" || (event.ctrlKey && event.key === "r")) {
        event.preventDefault();
        event.stopPropagation();
      }
    };

    const preventUnload = (event) => {
      // Prevent reloads without showing an alert
      event.preventDefault();
    };

    window.addEventListener("keydown", preventReload);
    window.addEventListener("beforeunload", preventUnload);

    return () => {
      window.removeEventListener("keydown", preventReload);
      window.removeEventListener("beforeunload", preventUnload);
    };
  }, []);

  useEffect(() => {
    (async () => {
      if (location.state?.sinistro) {
        setSinistro({
          id: null,
          id_tipologia_sinistro: "",
          stato: "red",
          id_condominio: location.state?.sinistro?.id_condominio,
          condominio: "",
          id_compagnia_assicurativa:
            location.state?.sinistro?.id_compagnia_assicurativa,
          data: location.state?.sinistro?.data,
          data_esercizio: location.state?.sinistro?.data_esercizio,
          appunti: "",
          alert: "",
          id_impresa: "",
          riferimento: location.state?.sinistro?.riferimento,
          id_perito: location.state?.sinistro?.id_perito,
          id_studio_peritale: location.state?.sinistro?.id_studio_peritale,
          n_sinistro: "",
          co_assicurazione: 0,
          danno_condominiale: 0,
          danno: "",
          riferimento_danno: "",
          id_sinistro_collegato: location.state?.sinistro?.id,
        });
      } else {
        setSinistro({
          id_amministratore: amministratore,
          riferimento: location.state?.riferimento + 1,
          id_tipologia_sinistro: null,
          stato: "red",
          id_condominio: null,
          condominio: "",
          id_compagnia_assicurativa: null,
          id_agenzia: null,
          data: new Date(),
          data_esercizio: new Date(),
          appunti: "",
          alert: "",
          id_impresa: null,
          id_perito: null,
          id_studio_peritale: null,
          n_sinistro: "",
          co_assicurazione: 0,
          danno_condominiale: 0,
          pagamento_condominiale: 0,
          danno: "",
          riferimento_danno: "",
          id_sinistro_collegato: null,
          ol_scheda: "",
          mail_incarico: "",
          custode: "",
        });
      }
      let responseStudio = await api.get("studi_peritali");
      setStudiPeritali(responseStudio.data.data);

      let responseTipologieSinistri = await api.get("tipologie_sinistri");
      setTipologieSinistri(responseTipologieSinistri.data.data);
      let responseImprese;
      let responseCondominii;
      if (amministratore !== 0) {
        responseCondominii = await api.get("condominii/" + amministratore);
        //responseImprese = await api.get("imprese/" + amministratore);
      } else {
        //responseImprese = await api.get("imprese");
        responseCondominii = await api.get("condominii");
      }
      responseImprese = await api.get("imprese");
      setCondominii(responseCondominii.data.data);
      setImprese(responseImprese.data.data);

      let responseCompagnie = await api.get("compagnie_assicurative");
      setCompagnie(responseCompagnie.data.data);

      let responseAgenzie = await api.get("agenzie");
      setAgenzie(responseAgenzie.data.data);
    })();
  }, [location]);

  return (
    <>
      <div className="page-container ">
        <div className="grid grid-cols-2 gap-2 px-2 ">
          <div className="col-span-2 flex items-center justify-between w-full mb-2">
            <h2 className="text-3xl text-center flex-1">
              Rif. - {sinistro?.riferimento}/
              {sinistro?.data_esercizio
                ? moment(sinistro?.data_esercizio).format("YY")
                : "Anno "}
              {sinistro?.condominio !== ""
                ? sinistro?.condominio
                : " Condominio "}
              {sinistro?.danno !== "" ? sinistro?.danno : " Parte Danneggiata "}
              {relPersoneInteressate?.length > 0
                ? " " + relPersoneInteressate?.map((el) => el.cognome)
                : " Cognomi "}
            </h2>
          </div>
          <div className="col-span-2 md:col-span-1 ">
            <DataInput
              label={"Data"}
              name="data"
              onChange={(e) => {
                setSessionModified();
                setSinistro({
                  ...sinistro,
                  data: moment(e).format("YYYY-MM-DD"),
                });
              }}
              value={moment(sinistro?.data)}
            />
          </div>
          <div className="col-span-2 md:col-span-1">
            <StatoSinistri
              onChange={(val) => {
                setSessionModified();
                setSinistro({ ...sinistro, stato: val });
              }}
              value={sinistro?.stato}
              label={"Stato"}
            />
          </div>
          <div className="col-span-2 md:col-span-1 ">
            <Autocomplete
              suggestions={tipologieSinistri}
              fields={["nome"]}
              keyField="id"
              value={sinistro?.id_tipologia_sinistro}
              id="nome"
              label="Tipologia Sinistri"
              name="nome"
              onChangeFunc={(e) => {
                setSessionModified();
                setSinistro({
                  ...sinistro,
                  id_tipologia_sinistro: e,
                });
              }}
              icon={<HiOutlineBuildingOffice className="text-2xl" />}
            />
          </div>
          <div className="col-span-2 md:col-span-1 flex items-end gap-2">
            <Autocomplete
              suggestions={condominii}
              fields={["nome"]}
              keyField="id"
              value={sinistro?.id_condominio}
              id="nome"
              label="Condominio"
              name="nome"
              onChangeFunc={(e) => {
                setSessionModified();
                //IN BASE AL CONDOMINIO SELEZIONATO CARICO COMPAGNIA ASSOCIATA
                setSinistro({
                  ...sinistro,
                  id_condominio: e,
                  condominio: condominii?.filter((el) => el.id === e)[0].nome,
                  id_compagnia_assicurativa: condominii?.filter(
                    (el) => el.id === e
                  )[0].id_compagnia_assicurativa,
                  id_agenzia: condominii?.filter((el) => el.id === e)[0]
                    .id_agenzia,
                });
              }}
              icon={<HiOutlineBuildingOffice className="text-2xl" />}
            />
            <div
              className="icon-container-green group "
              onClick={() => setOpenNewCondominio(true)}
            >
              <HiOutlinePlus className="icon-green" />
            </div>
          </div>
          <div className="col-span-2 md:col-span-1 ">
            <Input
              label={"N° Sinistro"}
              type="text"
              name="n_sinistro"
              onChange={handleChange}
              value={sinistro?.n_sinistro}
              icon={<HiOutlineListBullet className="text-2xl" />}
            />
          </div>
          <div className="col-span-2 md:col-span-1 flex gap-2">
            <div className="flex flex-col w-1/2">
              <DataInput
                label={"Data Esercizio"}
                name="data_esercizio"
                onChange={(e) => {
                  setSessionModified();
                  setSinistro({
                    ...sinistro,
                    data_esercizio: moment(e).format("YYYY-MM-DD"),
                  });
                }}
                value={moment(sinistro?.data_esercizio)}
              />
            </div>
            <Input
              label={"Riferimento"}
              type="text"
              name="riferimento"
              onChange={handleChange}
              value={sinistro?.riferimento}
              icon={<HiOutlineHashtag className="text-2xl" />}
            />
          </div>

          <div className="col-span-2 md:col-span-1 flex">
            <Autocomplete
              disabled={true}
              suggestions={compagnie}
              fields={["nome"]}
              keyField="id"
              value={sinistro?.id_compagnia_assicurativa}
              id="nome"
              label="Compagnia"
              name="nome"
              onChangeFunc={(e) => {
                setSessionModified();
                setSinistro({ ...sinistro, id_compagnia_assicurativa: e });
              }}
              icon={<HiOutlineBriefcase className="text-2xl" />}
            />
          </div>
          <div className="col-span-2 md:col-span-1 ">
            <Autocomplete
              disabled={true}
              suggestions={agenzie}
              fields={["nome"]}
              keyField="id"
              value={sinistro?.id_agenzia}
              id="nome"
              label="Agenzia"
              name="nome"
              onChangeFunc={(e) => {
                setSessionModified();
                setSinistro({ ...sinistro, id_agenzia: e });
              }}
              icon={<HiOutlineBriefcase className="text-2xl" />}
            />
          </div>
          <div className="col-span-2 md:col-span-1  flex items-end gap-2">
            <Autocomplete
              suggestions={studiPeritali}
              fields={["nome"]}
              keyField="id"
              value={sinistro?.id_studio_peritale}
              id="nome"
              label="Studio Peritale"
              name="nome"
              onChangeFunc={async (e) => {
                setSessionModified();
                setSinistro({ ...sinistro, id_studio_peritale: e });
                let responsePeriti = await api.get(
                  "studi_peritali/periti/" + e
                );
                setPeriti(responsePeriti.data.data);
              }}
              icon={<HiOutlineUser className="text-2xl" />}
            />
            <div
              className="icon-container-green group "
              onClick={() => setOpenNewStudio(true)}
            >
              <HiOutlinePlus className="icon-green" />
            </div>
          </div>
          <div className="col-span-2 md:col-span-1  flex items-end gap-2">
            <Autocomplete
              disabled={sinistro?.id_studio_peritale === ""}
              suggestions={periti}
              fields={["cognome"]}
              keyField="id"
              value={sinistro?.id_perito}
              id="cognome"
              label="Perito"
              name="cognome"
              onChangeFunc={(e) => {
                setSessionModified();
                setSinistro({ ...sinistro, id_perito: e });
              }}
              icon={<HiOutlineUser className="text-2xl" />}
            />
            {sinistro?.id_studio_peritale && (
              <div
                className="icon-container-green group "
                onClick={() => setOpenNewPerito(true)}
              >
                <HiOutlinePlus className="icon-green" />
              </div>
            )}
          </div>
          <div className="col-span-2 md:col-span-1 ">
            <Input
              label={"Riferimento Perito"}
              type="text"
              name="rif_perito"
              onChange={handleChange}
              value={sinistro?.rif_perito}
              icon={<HiOutlineUserCircle className="text-2xl" />}
            />
          </div>
          <div className="col-span-2 md:col-span-1 ">
            <Input
              label={"Mail Incarico"}
              type="text"
              name="mail_incarico"
              onChange={handleChange}
              value={sinistro?.mail_incarico}
              icon={<HiOutlineCalendar className="text-2xl" />}
            />
          </div>
          <div className="col-span-2 ">
            <Input
              label={"Custode"}
              type="text"
              name="custode"
              onChange={handleChange}
              value={sinistro?.custode}
              icon={<HiOutlineUser className="text-2xl" />}
            />
          </div>
          <div className="col-span-2 md:col-span-1 ">
            <Checkbox
              label={"Co Assicurazione"}
              name={"co_assicurazione"}
              value={sinistro?.co_assicurazione === 1}
              onChange={handleCheckbox}
            />
          </div>

          <div className="col-span-2 md:col-span-1 ">
            <Checkbox
              label={"Danno Condominiale"}
              name={"danno_condominiale"}
              value={sinistro?.danno_condominiale === 1}
              onChange={handleCheckbox}
            />
          </div>

          {sinistro?.danno_condominiale === 1 && (
            <>
              <div className="col-span-2 md:col-span-1 ">
                <Input
                  label={"Parte Danneggiata"}
                  type="text"
                  name="danno"
                  onChange={handleChange}
                  value={sinistro?.danno}
                  icon={<HiOutlineExclamationTriangle className="text-2xl" />}
                />
              </div>
              <div className="col-span-2 md:col-span-1 ">
                <Input
                  label={"Riferimento Parte Danneggiata"}
                  type="text"
                  name="riferimento_danno"
                  onChange={handleChange}
                  value={sinistro?.riferimento_danno}
                  icon={<HiOutlineExclamationTriangle className="text-2xl" />}
                />
              </div>
            </>
          )}
          {sessionStorage.getItem("ol_scheda") === "1" && (
            <div className="col-span-2 md:col-span-1 ">
              <Input
                label={"OL/Scheda"}
                type="text"
                name="ol_scheda"
                onChange={handleChange}
                value={sinistro?.ol_scheda}
                icon={<HiOutlineListBullet className="text-2xl" />}
              />
            </div>
          )}
          <div className="col-span-2 ">
            <span className="text-lg font-normal">Appunti</span>
            <div className="relative">
              <span className="absolute left-2 flex items-start pr-2 pt-2 h-full">
                <HiOutlineClipboard className="text-2xl" />
              </span>

              <textarea
                type="text"
                name="appunti"
                className="input flex items-center"
                placeholder="Inserisci Appunti"
                onChange={handleChange}
                value={sinistro?.appunti}
              />
            </div>
          </div>
          <div className="col-span-2 flex gap-4 justify-end flex-1 mb-2">
            <div className="icon-container-green group " onClick={handleSave}>
              <HiOutlineCheck className="icon-green" />
            </div>
          </div>
          {sinistro?.id && (
            <>
              <div className="col-span-2 h-1 bg-sinistri-lightgreen rounded-full"></div>
              <div className="col-span-1 flex gap-4 items-center justify-end flex-1 mt-6 h-80">
                <DataGridPro
                  ignoreDiacritics
                  rows={relImprese}
                  columns={columnsImprese}
                  getRowId={(row) => row.id}
                  slots={{ toolbar: CustomToolbarImprese }}
                  disableRowSelectionOnClick
                />
              </div>
              <div className="col-span-1 flex gap-4 items-center justify-end flex-1 mt-6 h-80">
                <DataGridPro
                  ignoreDiacritics
                  rows={relPersoneInteressate}
                  columns={columnsPersone}
                  getRowId={(row) => row.id}
                  slots={{ toolbar: CustomToolbarPersone }}
                  disableRowSelectionOnClick
                />
              </div>
              {sinistro?.co_assicurazione === 1 && (
                <>
                  <div className="col-span-2 h-1 bg-sinistri-lightgreen rounded-full"></div>
                  <div className="col-span-2 text-center text-2xl">
                    CO ASSICURAZIONE
                  </div>
                  <div className="col-span-2 flex gap-4 items-center justify-end flex-1 mt-2 h-80">
                    <DataGridPro
                      ignoreDiacritics
                      rows={relCoAss}
                      columns={columnsCoAssicurazione}
                      getRowId={(row) => row.id}
                      slots={{ toolbar: CustomToolbarCoAssicurazione }}
                      disableRowSelectionOnClick
                    />
                  </div>
                </>
              )}
            </>
          )}
        </div>
      </div>
      <Modal
        open={openNewCondominio}
        handleClose={async () => {
          let responseCondominio = await api.get(
            "condominii/" + amministratore
          );
          setCondominii(responseCondominio.data.data);
          if (responseCondominio.data.data?.length !== condominii?.length) {
            let compagnia = sinistro?.id_compagnia_assicurativa;
            let responseCompagnie = await api.get("compagnie_assicurative");
            //aggiorno automaticamente il valore del sinistro.id_compagnia_assicurativa
            if (responseCompagnie.data.data?.length !== compagnie?.length) {
              setCompagnie(responseCompagnie.data.data);
              compagnia =
                /* responseCompagnie.data.data[
                  responseCompagnie.data.data.length - 1
                ]?.id; */
                findNewObject(responseCompagnie.data.data, compagnie)[0]?.id;
            } else {
              compagnia =
                /* responseCondominio.data.data[
                  responseCondominio.data.data.length - 1
                ]?.id_compagnia_assicurativa; */
                findNewObject(responseCondominio.data.data, condominii)[0]
                  ?.id_compagnia_assicurativa;
            }
            let agenzia = sinistro?.id_agenzia;
            let responseAgenzie = await api.get("agenzie");
            //aggiorno automaticamente il valore del sinistro.id_agenzia
            if (responseAgenzie.data.data?.length !== agenzie?.length) {
              setAgenzie(responseAgenzie.data.data);
              agenzia =
                /* responseAgenzie.data.data[responseAgenzie.data.data.length - 1]
                  ?.id; */
                findNewObject(responseAgenzie.data.data, agenzie)[0]?.id;
            } else {
              agenzia =
                /* responseCondominio.data.data[
                  responseCondominio.data.data.length - 1
                ]?.id_agenzia; */
                findNewObject(responseCondominio.data.data, condominii)[0]
                  ?.id_agenzia;
            }
            setSinistro({
              ...sinistro,
              //aggiorno automaticamente il valore del sinistro.id_condominio
              id_condominio:
                /* responseCondominio.data.data[
                  responseCondominio.data.data.length - 1
                ]?.id, */
                findNewObject(responseCondominio.data.data, condominii)[0]?.id,
              id_compagnia_assicurativa: compagnia,
              id_agenzia: agenzia,
            });
          }
          setOpenNewCondominio(false);
        }}
      >
        <h2 className="text-2xl text-center mb-4">Nuovo Condominio</h2>
        <NuovoCondominio external={true} />
      </Modal>
      <Modal
        open={openNewStudio}
        handleClose={async () => {
          let responseStudio = await api.get("studi_peritali");
          setStudiPeritali(responseStudio.data.data);
          if (responseStudio.data.data?.length !== studiPeritali?.length) {
            setSinistro({
              ...sinistro,
              id_studio_peritale:
                /* responseStudio.data.data[responseStudio.data.data.length - 1]
                  ?.id, */
                findNewObject(responseStudio.data.data, studiPeritali)[0]?.id,
            });
          }
          setOpenNewStudio(false);
        }}
      >
        <h2 className="text-2xl text-center mb-4">Nuovo Studio Peritale</h2>
        <NuovoStudioPeritale external={true} />
      </Modal>
      <Modal
        open={openNewPerito}
        handleClose={async () => {
          let responsePeriti = await api.get(
            "studi_peritali/periti/" + sinistro?.id_studio_peritale
          );
          setPeriti(responsePeriti.data.data);
          if (responsePeriti.data.data?.length !== periti?.length) {
            setSinistro({
              ...sinistro,
              id_perito:
                /* responsePeriti.data.data[responsePeriti.data.data.length - 1]
                  ?.id, */
                findNewObject(responsePeriti.data.data, periti)[0]?.id,
            });
          }
          setOpenNewPerito(false);
        }}
      >
        <h2 className="text-2xl text-center mb-4">Nuovo Perito</h2>
        <NuovoPerito external={true} idStudio={sinistro?.id_studio_peritale} />
      </Modal>
    </>
  );
};

export const DettaglioPartica = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [sinistro, setSinistro] = useState(null);

  const [openCoAssicurazione, setOpenCoAssicurazione] = useState(false);
  const [operazioneCoAss, setOperazioneCoAss] = useState("edit");
  const [coAssicurazione, setCoAssicurazione] = useState({
    id_compagnia_assicurativa: null,
    id_perito: null,
    n_sinistro: "",
    liquidazione: 0,
  });

  const [amministratore, setAmministratore] = useContext(Amministratore);
  const [condominii, setCondominii] = useState([]);
  const [compagnie, setCompagnie] = useState([]);
  const [agenzie, setAgenzie] = useState([]);
  const [storicoEmail, setStoricoEmail] = useState([]);
  const [emailSel, setEmailSel] = useState(null);
  const [tipologieSinistri, setTipologieSinistri] = useState([]);
  const [periti, setPeriti] = useState([]);
  const [studiPeritali, setStudiPeritali] = useState([]);

  const [promemoria, setPromemoria] = useState("");

  const [emailOpen, setEmailOpen] = useState(false);
  const [openDeleteEmail, setOpenDeleteEmail] = useState(false);

  const [loadingGenerali, setLoadingGenerali] = useState(false);
  const [loadingDocumentazione, setLoadingDocumentazione] = useState(false);
  const [loadingDenuncia, setLoadingDenuncia] = useState(false);
  const [loadingLiquidazione, setLoadingLiquidazione] = useState(false);

  const [openNewStudio, setOpenNewStudio] = useState(false);
  const [openNewPerito, setOpenNewPerito] = useState(false);
  const [openNewPersona, setOpenNewPersona] = useState(false);

  const [operazionePersonaInteressata, setOperazionePersonaInteressata] =
    useState("new");
  const [personaInteressata, setPersonaInteressata] = useState({
    id_persona_interessata: "",
  });
  const [personeInteressate, setPersoneInteressate] = useState([]);
  const [openPersonaInteressata, setOpenPersonaInteressata] = useState(false);
  const [relPersoneInteressate, setRelPersoneInteressate] = useState([]);

  const [relImprese, setRelImprese] = useState([]);
  const [imprese, setImprese] = useState([]);

  const [relCoAss, setRelCoAss] = useState([]);

  const [openImpresa, setOpenImpresa] = useState(false);
  const [operazioneImpresa, setOperazioneImpresa] = useState("edit");
  const [impresa, setImpresa] = useState({
    id_impresa: null,
    id_sinistro: "",
    fattura: 0,
    n_fattura: "",
    intervento: "",
    anno: "",
    privato: "",
  });

  const [congnomiPersone, setCognomiPersone] = useState("");

  const [openDelete, setOpenDelete] = useState(false);
  const [openDeletePersone, setOpenDeletePersone] = useState(false);

  const [liquidazioni, setLiquidazioni] = useState([]);
  const [denunce, setDenunce] = useState([]);

  const [nomeSinistro, setNomeSinistro] = useState("");
  const [documentazione, setDocumentazione] = useState(null);

  //EMAILS
  const [open, setOpen] = useState(false);
  const [emailType, setEmailType] = useState("");
  const [selectedSinistri, setSelectedSinistri] = useState([]);
  const [openSelect, setOpenSelect] = useState(false);

  const [tab, setTab] = useState(1);
  const tabs = [
    {
      icon: <HiOutlinePencilAlt className="icon-tab-panel" />,
      text: "Dati Generali",
      id: 1,
    },
    /* {
      icon: <HiOutlineUserGroup className="icon-tab-panel" />,
      text: "Persone Interessate",
      id: 2,
    }, */
    {
      icon: <HiOutlineInboxStack className="icon-tab-panel" />,
      text: "Documentazione",
      id: 3,
    },
    {
      icon: <HiOutlineMail className="icon-tab-panel" />,
      text: "Denuncia",
      id: 4,
    },
    {
      icon: <HiOutlineCurrencyEuro className="icon-tab-panel" />,
      text: "Liquidazione",
      id: 5,
    },
  ];

  const setSessionModified = () => {
    if (sessionStorage.getItem("modified") !== "1") {
      sessionStorage.setItem("modified", "1");
    }
  };
  const handleCheckbox = (e) => {
    setSessionModified();
    setSinistro({
      ...sinistro,
      [e.target.name]: sinistro[e.target.name] === 1 ? 0 : 1,
    });
  };

  const handleDeletePersona = async () => {
    try {
      await api.delete("persone_interessate/rel/" + personaInteressata?.id);
      toast.success("Eliminazione completata!");
    } catch (error) {
      toast.error("Errore durante l'eliminazione. Riprova!");
    }
    setOpenDeletePersone(false);
    handleClosePersone();
  };
  const handleDeleteEmail = async () => {
    try {
      if (emailSel.tipologia_mail === "Denunce") {
        await api.delete("denunce/" + emailSel?.id);
      } else {
        await api.delete("email/" + emailSel?.id);
      }
      let responseStoricoEmail = await api.get(
        "email/sinistro/" + location.state?.sinistro.id
      );
      setStoricoEmail(responseStoricoEmail.data.data);
      setEmailOpen(false);
      toast.success("Eliminazione completata!");
    } catch (error) {
      toast.error("Errore durante l'eliminazione. Riprova!");
    }
    setOpenDeleteEmail(false);
  };
  const handleSavePersona = async () => {
    if (operazionePersonaInteressata === "new") {
      try {
        await api.post("persone_interessate/rel", {
          persona_interessata: {
            ...personaInteressata,
            id_sinistro: sinistro?.id,
          },
        });
        toast.success("Salvataggio completato!");
        handleClosePersone();
      } catch (error) {
        toast.error("Errore durante il salvataggio. Riprova!");
      }
    } else {
      try {
        await api.put("persone_interessate/rel", {
          persona_interessata: {
            ...personaInteressata,
            id_sinistro: sinistro?.id,
          },
        });
        toast.success("Modifica completata!");
        handleClosePersone();
      } catch (error) {
        toast.error("Errore durante la modifica. Riprova!");
      }
    }
  };
  const getPersoneInteressate = async () => {
    /* PERSONE INTERESSATE */
    let responseRelPersone = await api.get(
      "persone_interessate/sinistro/" + location.state?.sinistro.id
    );
    setRelPersoneInteressate(responseRelPersone.data.data);
    let responsePersone = await api.get(
      "persone_interessate/condominio/" +
        location.state?.sinistro?.id_condominio
    );
    const differencePersoneInteressate = responsePersone.data.data?.filter(
      (persona) =>
        responseRelPersone.data.data
          ?.map((relPersona) => relPersona.id_persona_interessata)
          .indexOf(persona.id) === -1
    );
    setPersoneInteressate(differencePersoneInteressate);
  };
  const handleClosePersone = async () => {
    setOpenPersonaInteressata(false);
    setOperazionePersonaInteressata("new");
    getPersoneInteressate();
  };

  const handleChange = (e) => {
    setSessionModified();
    setSinistro({ ...sinistro, [e.target.name]: e.target.value });
  };
  const handleSave = async () => {
    const riferimenti = await api.get("sinistri/riferimento/" + amministratore);
    const rif = riferimenti.data.data
      ?.filter(
        (el) =>
          el.id !== location.state?.sinistro.id && el?.data_esercizio !== null
      )
      ?.map(
        (riferimento) =>
          riferimento.riferimento +
          moment(riferimento?.data_esercizio).format("YYYY")
      );

    if (
      rif.includes(
        sinistro.riferimento + moment(sinistro?.data_esercizio).format("YYYY")
      )
    ) {
      toast.error("Numero riferimento già esistente!");
    } else {
      try {
        await api.put("sinistri", {
          sinistro: {
            ...sinistro,
            data: sinistro?.data
              ? moment(sinistro?.data).format("YYYY-MM-DD")
              : null,
            data_esercizio: sinistro?.data_esercizio
              ? moment(sinistro?.data_esercizio).format("YYYY-MM-DD")
              : null,
            utente: sessionStorage.getItem("Username"),
          },
        });
        toast.success("Modifica completata!");
        sessionStorage.removeItem("modified");
      } catch (error) {
        toast.error("Errore durante la modifica. Riprova!");
      }
    }
  };
  const handleDelete = async () => {
    try {
      await api.delete("sinistri/" + sinistro?.id);
      toast.success("Eliminazione completata!");
    } catch (error) {
      toast.error("Errore durante l'eliminazione. Riprova!");
    }
    setOpenDelete(false);
  };
  const getDocumentazioni = async () => {
    setLoadingDocumentazione(true);
    let responseDoc = await api.get(
      "documentazioni/sinistro/" + location.state.sinistro?.id
    );
    if (responseDoc.data.data.length > 0) {
      setDocumentazione(responseDoc.data.data[0]);
    }
    setLoadingDocumentazione(false);
  };

  //EMAILS
  const handleEmailType = async (type) => {
    let response = await api.get("tipologie_mail/" + type);
    let testo = response.data.data[0].testo;
    navigate("/Invio-Email", {
      state: {
        type: type,
        selectedSinistri: [{ ...sinistro }],
        testo: testo,
      },
    });
  };

  function ToolbarPersoneInteressate() {
    return (
      <GridToolbarContainer>
        <div className="flex items-center justify-between w-full mb-2">
          <GridToolbarQuickFilter placeholder="Cerca..." />

          <div className="flex gap-2">
            <div
              className="icon-container-green group "
              onClick={() => {
                let ordine =
                  relPersoneInteressate.length > 0
                    ? Math.max(...relPersoneInteressate.map((o) => o.ordine))
                    : 0;
                setPersonaInteressata({
                  id_persona_interessata: "",
                  ordine: ordine + 1,
                });
                setOpenPersonaInteressata(true);
              }}
            >
              <HiOutlinePlus className="icon-green" />
            </div>
          </div>
        </div>
      </GridToolbarContainer>
    );
  }
  function CustomToolbarCoAssicurazione() {
    return (
      <GridToolbarContainer>
        <div className="flex items-center justify-between w-full mb-2">
          {/* <div className="col-span-2 text-center text-2xl">
            Co Assicurazione
          </div> */}
          <div></div>
          <div className="flex gap-2">
            <div
              className="icon-container-green group "
              onClick={() => {
                setOperazioneCoAss("new");
                setCoAssicurazione({
                  id_compagnia_assicurativa: null,
                  id_perito: null,
                  n_sinistro: "",
                  liquidazione: 0,
                });
                setOpenCoAssicurazione(true);
              }}
            >
              <HiOutlinePlus className="icon-green" />
            </div>
          </div>
        </div>
      </GridToolbarContainer>
    );
  }
  function CustomToolbarImprese() {
    return (
      <GridToolbarContainer>
        <div className="flex items-center justify-between w-full mb-2">
          {/* <div className="col-span-2 text-center text-2xl">Imprese</div> */}
          <div></div>
          <div className="flex gap-2">
            <div
              className="icon-container-green group "
              onClick={() => {
                setOperazioneImpresa("new");
                setImpresa({
                  id_impresa: null,
                  id_sinistro: "",
                  fattura: 0,
                  n_fattura: "",
                  intervento: "",
                  anno: "",
                  privato: "",
                });
                setOpenImpresa(true);
              }}
            >
              <HiOutlinePlus className="icon-green" />
            </div>
          </div>
        </div>
      </GridToolbarContainer>
    );
  }

  useEffect(() => {
    const preventReload = (event) => {
      // Prevent default behavior for F5 and Ctrl+R
      if (event.key === "F5" || (event.ctrlKey && event.key === "r")) {
        event.preventDefault();
        event.stopPropagation();
      }
    };

    const preventUnload = (event) => {
      // Prevent reloads without showing an alert
      event.preventDefault();
    };

    window.addEventListener("keydown", preventReload);
    window.addEventListener("beforeunload", preventUnload);

    return () => {
      window.removeEventListener("keydown", preventReload);
      window.removeEventListener("beforeunload", preventUnload);
    };
  }, []);

  useEffect(() => {
    (async () => {
      switch (tab) {
        case 1:
          setLoadingGenerali(true);
          let response = await api.get(
            "sinistri/singolo/" + location.state?.sinistro.id
          );
          setSinistro({
            ...location.state?.sinistro,
            ...response.data.data[0],
          });
          //setSinistro(location.state?.sinistro);
          let riferimento = location.state?.sinistro?.riferimento;
          let data = location.state?.sinistro?.data
            ? moment(location.state?.sinistro?.data).format("YY") + " "
            : "";
          let data_esercizio = location.state?.sinistro?.data_esercizio
            ? moment(location.state?.sinistro?.data_esercizio).format("YY") +
              " "
            : "";
          let condominio =
            location.state?.sinistro?.condominio !== ""
              ? location.state?.sinistro?.condominio + " "
              : " ";
          let danno =
            location.state?.sinistro?.danno !== ""
              ? location.state?.sinistro?.danno + " "
              : " ";
          let persone = location.state?.sinistro?.persone
            ? location.state?.sinistro?.persone
            : "";
          setNomeSinistro(
            riferimento +
              "/" +
              data_esercizio +
              " " +
              condominio +
              " " +
              danno +
              " " +
              persone
          );

          let responseStudio = await api.get("studi_peritali");
          setStudiPeritali(responseStudio.data.data);
          if (location.state?.sinistro?.id_studio_peritale) {
            let responsePeriti = await api.get(
              "studi_peritali/periti/" +
                location.state?.sinistro.id_studio_peritale
            );
            setPeriti(responsePeriti.data.data);
          }
          let responseTipologieSinistri = await api.get("tipologie_sinistri");
          setTipologieSinistri(responseTipologieSinistri.data.data);
          let responseCondominii;
          let responseImprese;
          let responsePromemoria;
          if (amministratore !== 0) {
            responseCondominii = await api.get("condominii/" + amministratore);
            //responseImprese = await api.get("imprese/" + amministratore);
            responsePromemoria = await api.get("promemoria/" + amministratore);
          } else {
            //responseImprese = await api.get("imprese");
            responseCondominii = await api.get("condominii");
            responsePromemoria = await api.get("promemoria");
          }
          responseImprese = await api.get("imprese");
          setCondominii(responseCondominii.data.data);
          setImprese(responseImprese.data.data);
          if (
            responsePromemoria.data.data?.filter(
              (el) => el.id_sinistro === location.state?.sinistro.id
            ).length > 0
          ) {
            setPromemoria(
              responsePromemoria.data.data?.filter(
                (el) => el.id_sinistro === location.state?.sinistro.id
              )[0]?.testo
            );
          } else {
            setPromemoria("");
          }

          let responseCompagnie = await api.get("compagnie_assicurative");
          setCompagnie(responseCompagnie.data.data);

          let responseAgenzie = await api.get("agenzie");
          setAgenzie(responseAgenzie.data.data);

          /* TABELLE RELAZIONALI */
          let responseCoAss = await api.get(
            "compagnie_assicurative/sinistro/" + location.state?.sinistro.id
          );
          setRelCoAss(responseCoAss.data.data);
          let responseImpresa = await api.get(
            "imprese/sinistro/" + location.state?.sinistro.id
          );
          setRelImprese(responseImpresa.data.data);
          let responseStoricoEmail = await api.get(
            "email/sinistro/" + location.state?.sinistro.id
          );
          setStoricoEmail(responseStoricoEmail.data.data);
          getPersoneInteressate();
          setLoadingGenerali(false);
          break;
        case 3:
          /* DOCUMENTAZIONI */
          getDocumentazioni();
          break;
        case 4:
          /* DENUNCE */
          getDenunce();
          break;
        case 5:
          setLoadingLiquidazione(true);
          /* FATTURA */
          let responseLiquidazioni = await api.get(
            "offerte/" + location.state?.sinistro.id
          );
          setLiquidazioni(responseLiquidazioni.data.data);
          setLoadingLiquidazione(false);
          break;
        default:
          break;
      }
    })();
  }, [tab]);

  const getDenunce = async () => {
    setLoadingDenuncia(true);
    let responseDenunce = await api.get(
      "denunce/sinistro/" + location.state?.sinistro.id
    );
    setDenunce(responseDenunce.data.data);
    setLoadingDenuncia(false);
  };

  useEffect(() => {
    (async () => {
      let riferimento = location.state?.sinistro?.riferimento;
      let data = location.state?.sinistro?.data
        ? moment(location.state?.sinistro?.data).format("YY") + " "
        : "";
      let data_esercizio = location.state?.sinistro?.data_esercizio
        ? moment(location.state?.sinistro?.data_esercizio).format("YY") + " "
        : "";
      let condominio =
        location.state?.sinistro?.condominio !== ""
          ? location.state?.sinistro?.condominio + " "
          : " ";
      let danno = sinistro?.danno !== "" ? sinistro?.danno + " " : " ";
      let persone =
        relPersoneInteressate?.length > 0
          ? relPersoneInteressate.map((el) => el.cognome)
          : "";
      //setSinistro({ ...sinistro, persone: persone, danno: danno });
      setCognomiPersone(persone);
      setNomeSinistro(
        riferimento +
          "/" +
          data_esercizio +
          " " +
          condominio +
          " " +
          danno +
          " " +
          persone
      );
    })();
  }, [relPersoneInteressate, personeInteressate, sinistro]);

  const handleCloseImpresa = async () => {
    let responseImpresa = await api.get("imprese/sinistro/" + sinistro?.id);
    setRelImprese(responseImpresa.data.data);
    //setImprese(imprese.filter((impresa) => impresa.id !== impresa.id_impresa));
    setOpenImpresa(false);
  };

  const handleCloseCoAss = async () => {
    let responseCoAss = await api.get(
      "compagnie_assicurative/sinistro/" + sinistro?.id
    );
    setRelCoAss(responseCoAss.data.data);
    //setImprese(imprese.filter((impresa) => impresa.id !== impresa.id_impresa));
    setOpenCoAssicurazione(false);
  };

  return (
    <>
      <div className="page-container ">
        <div className="w-full flex pb-2">
          <div className="flex items-center justify-between w-full">
            <div className=""></div>
            <h2 className="text-lg lg:text-3xl text-center flex-1 uppercase">
              {nomeSinistro}
            </h2>
            <div className="flex justify-end">
              {sinistro && (
                <div
                  className="icon-container-blue group"
                  onClick={() => setOpen(true)}
                >
                  <HiOutlineMail className="icon-blue" />
                </div>
              )}
            </div>
          </div>
        </div>

        <TabPanel
          tab={tab}
          setTab={setTab}
          tabs={tabs}
          content={[
            {
              id: 1,
              child: (
                <>
                  {loadingGenerali ? (
                    <div className="flex flex-wrap gap-2">
                      <div className="flex w-full gap-2">
                        <Skeleton variant="rounded" width="50%" height={70} />
                        <Skeleton variant="rounded" width="50%" height={70} />
                      </div>
                      <div className="flex w-full gap-2">
                        <Skeleton variant="rounded" width="50%" height={70} />
                        <Skeleton variant="rounded" width="50%" height={70} />
                      </div>
                      <div className="flex w-full gap-2">
                        <Skeleton variant="rounded" width="50%" height={70} />
                        <Skeleton variant="rounded" width="50%" height={70} />
                      </div>
                      <div className="flex w-full gap-2">
                        <Skeleton variant="rounded" width="50%" height={70} />
                        <Skeleton variant="rounded" width="50%" height={70} />
                      </div>
                      <div className="flex w-full gap-2">
                        <Skeleton variant="rounded" width="50%" height={70} />
                        <Skeleton variant="rounded" width="50%" height={70} />
                      </div>
                      <div className="flex w-full gap-2">
                        <Skeleton variant="rounded" width="50%" height={70} />
                        <Skeleton variant="rounded" width="50%" height={70} />
                      </div>
                      <div className="flex w-full gap-2">
                        <Skeleton variant="rounded" width="100%" height={150} />
                      </div>
                    </div>
                  ) : (
                    <>
                      <div className="grid grid-cols-2 gap-2 h-[calc(100vh_-_320px)]  lg:h-[calc(100vh_-_210px)] overflow-y-auto px-2 ">
                        <div className="col-span-2 md:col-span-1 ">
                          <DataInput
                            label={"Data"}
                            name="data"
                            onChange={(e) => {
                              setSessionModified();
                              setSinistro({
                                ...sinistro,
                                data: moment(e).format("YYYY-MM-DD"),
                              });
                            }}
                            value={moment(sinistro?.data)}
                          />
                        </div>
                        <div className="col-span-2 md:col-span-1">
                          <StatoSinistri
                            onChange={(val) => {
                              setSessionModified();
                              setSinistro({ ...sinistro, stato: val });
                            }}
                            value={sinistro?.stato}
                            label={"Stato"}
                          />
                        </div>
                        <div className="col-span-2 md:col-span-1">
                          <Autocomplete
                            suggestions={tipologieSinistri}
                            fields={["nome"]}
                            keyField="id"
                            value={sinistro?.id_tipologia_sinistro}
                            id="nome"
                            label="Tipologia Sinistri"
                            name="nome"
                            onChangeFunc={(e) => {
                              setSessionModified();
                              let tipologiaSel = tipologieSinistri.filter(
                                (el) => el.id === e
                              )[0];
                              setSinistro({
                                ...sinistro,
                                id_tipologia_sinistro: e,
                                tipologia_sinistro_testo: tipologiaSel?.testo,
                                tipologia_sinistro: tipologiaSel?.nome,
                              });
                            }}
                            icon={
                              <HiOutlineBuildingOffice className="text-2xl" />
                            }
                          />
                        </div>

                        <div className="col-span-2 md:col-span-1  flex items-end gap-2">
                          <Autocomplete
                            //disabled={true}
                            suggestions={condominii}
                            fields={["nome"]}
                            keyField="id"
                            value={sinistro?.id_condominio}
                            id="nome"
                            label="Condominio"
                            name="nome"
                            onChangeFunc={(e) => {
                              setSessionModified();
                              //setSinistro({ ...sinistro, id_condominio: e });
                              //IN BASE AL CONDOMINIO SELEZIONATO CARICO COMPAGNIA ASSOCIATA
                              setSinistro({
                                ...sinistro,
                                id_condominio: e,
                                condominio: condominii?.filter(
                                  (el) => el.id === e
                                )[0].nome,
                                id_compagnia_assicurativa: condominii?.filter(
                                  (el) => el.id === e
                                )[0].id_compagnia_assicurativa,
                                id_agenzia: condominii?.filter(
                                  (el) => el.id === e
                                )[0].id_agenzia,
                              });
                            }}
                            icon={
                              <HiOutlineBuildingOffice className="text-2xl" />
                            }
                          />
                        </div>
                        <div className="col-span-2 md:col-span-1 ">
                          <Input
                            label={"N° Sinistro"}
                            type="text"
                            name="n_sinistro"
                            onChange={(e) => {
                              setSinistro({
                                ...sinistro,
                                n_sinistro: e.target.value,
                                stato: "lightgrey",
                              });
                            }}
                            value={sinistro?.n_sinistro}
                            icon={<HiOutlineListBullet className="text-2xl" />}
                          />
                        </div>
                        <div className="col-span-2 md:col-span-1 flex gap-2">
                          <div className="flex flex-col w-1/2">
                            <DataInput
                              label={"Data Esercizio"}
                              name="data_esercizio"
                              onChange={(e) => {
                                setSessionModified();
                                setSinistro({
                                  ...sinistro,
                                  data_esercizio:
                                    moment(e).format("YYYY-MM-DD"),
                                });
                              }}
                              value={moment(sinistro?.data_esercizio)}
                            />
                          </div>
                          <Input
                            label={"Riferimento"}
                            type="text"
                            name="riferimento"
                            onChange={handleChange}
                            value={sinistro?.riferimento}
                            icon={<HiOutlineHashtag className="text-2xl" />}
                          />
                        </div>
                        <div className="col-span-2 md:col-span-1 ">
                          <Autocomplete
                            disabled={true}
                            suggestions={compagnie}
                            fields={["nome"]}
                            keyField="id"
                            value={sinistro?.id_compagnia_assicurativa}
                            id="nome"
                            label="Compagnia"
                            name="nome"
                            onChangeFunc={(e) => {
                              setSessionModified();
                              setSinistro({
                                ...sinistro,
                                id_compagnia_assicurativa: e,
                              });
                            }}
                            icon={<HiOutlineBriefcase className="text-2xl" />}
                          />
                        </div>
                        <div className="col-span-2 md:col-span-1 ">
                          <Autocomplete
                            disabled={true}
                            suggestions={agenzie}
                            fields={["nome"]}
                            keyField="id"
                            value={sinistro?.id_agenzia}
                            id="nome"
                            label="Agenzia"
                            name="nome"
                            onChangeFunc={(e) => {
                              setSessionModified();
                              setSinistro({ ...sinistro, id_agenzia: e });
                            }}
                            icon={<HiOutlineBriefcase className="text-2xl" />}
                          />
                        </div>
                        <div className="col-span-2 md:col-span-1 flex items-end gap-2">
                          <Autocomplete
                            suggestions={studiPeritali}
                            fields={["nome"]}
                            keyField="id"
                            value={sinistro?.id_studio_peritale}
                            id="nome"
                            label="Studio Peritale"
                            name="nome"
                            onChangeFunc={async (e) => {
                              setSessionModified();
                              setSinistro({
                                ...sinistro,
                                id_studio_peritale: e,
                              });
                              let responsePeriti = await api.get(
                                "studi_peritali/periti/" + e
                              );
                              setPeriti(responsePeriti.data.data);
                            }}
                            icon={<HiOutlineUser className="text-2xl" />}
                          />
                          <div
                            className="icon-container-green group "
                            onClick={() => setOpenNewStudio(true)}
                          >
                            <HiOutlinePlus className="icon-green" />
                          </div>
                        </div>

                        <div className="col-span-2 md:col-span-1 flex items-end gap-2">
                          <Autocomplete
                            disabled={sinistro?.id_studio_peritale === ""}
                            suggestions={periti}
                            fields={["cognome"]}
                            keyField="id"
                            value={sinistro?.id_perito}
                            id="cognome"
                            label="Perito"
                            name="cognome"
                            onChangeFunc={(e) => {
                              setSessionModified();
                              setSinistro({ ...sinistro, id_perito: e });
                            }}
                            icon={<HiOutlineUser className="text-2xl" />}
                          />
                          {sinistro?.id_studio_peritale && (
                            <div
                              className="icon-container-green group "
                              onClick={() => setOpenNewPerito(true)}
                            >
                              <HiOutlinePlus className="icon-green" />
                            </div>
                          )}
                        </div>
                        <div className="col-span-2 md:col-span-1 ">
                          <Input
                            label={"Riferimento Perito"}
                            type="text"
                            name="rif_perito"
                            onChange={handleChange}
                            value={sinistro?.rif_perito}
                            icon={<HiOutlineUserCircle className="text-2xl" />}
                          />
                        </div>
                        <div className="col-span-2 md:col-span-1 ">
                          <Input
                            label={"Mail Incarico"}
                            type="text"
                            name="mail_incarico"
                            onChange={handleChange}
                            value={sinistro?.mail_incarico}
                            icon={<HiOutlineCalendar className="text-2xl" />}
                          />
                        </div>
                        <div className="col-span-2 ">
                          <Input
                            label={"Custode"}
                            type="text"
                            name="custode"
                            onChange={handleChange}
                            value={sinistro?.custode}
                            icon={<HiOutlineUser className="text-2xl" />}
                          />
                        </div>
                        <div className="col-span-2 md:col-span-1 ">
                          <Checkbox
                            label={"Co Assicurazione"}
                            name={"co_assicurazione"}
                            value={sinistro?.co_assicurazione === 1}
                            onChange={handleCheckbox}
                          />
                        </div>
                        <div className="col-span-2 md:col-span-1 ">
                          <Checkbox
                            label={"Danno Condominiale"}
                            name={"danno_condominiale"}
                            value={sinistro?.danno_condominiale === 1}
                            onChange={handleCheckbox}
                          />
                        </div>

                        {sinistro?.danno_condominiale === 1 && (
                          <>
                            <div className="col-span-2 md:col-span-1 ">
                              <Input
                                label={"Parte Danneggiata"}
                                type="text"
                                name="danno"
                                onChange={handleChange}
                                value={sinistro?.danno}
                                icon={
                                  <HiOutlineExclamationTriangle className="text-2xl" />
                                }
                              />
                            </div>
                            <div className="col-span-2 md:col-span-1 ">
                              <Input
                                label={"Riferimento Parte Danneggiata"}
                                type="text"
                                name="riferimento_danno"
                                onChange={handleChange}
                                value={sinistro?.riferimento_danno}
                                icon={
                                  <HiOutlineExclamationTriangle className="text-2xl" />
                                }
                              />
                            </div>
                          </>
                        )}
                        {sessionStorage.getItem("ol_scheda") === "1" && (
                          <div className="col-span-2 md:col-span-1 ">
                            <Input
                              label={"OL/Scheda"}
                              type="text"
                              name="ol_scheda"
                              onChange={handleChange}
                              value={sinistro?.ol_scheda}
                              icon={
                                <HiOutlineListBullet className="text-2xl" />
                              }
                            />
                          </div>
                        )}
                        <div className="col-span-2 ">
                          <span className="text-base font-normal">Appunti</span>
                          <div className="relative">
                            <span className="absolute left-2 flex items-start pr-2 pt-2 h-full">
                              <HiOutlineClipboard className="text-2xl" />
                            </span>

                            <textarea
                              type="text"
                              name="appunti"
                              className="input flex items-center"
                              placeholder="Inserisci Appunti"
                              onChange={handleChange}
                              value={sinistro?.appunti}
                            />
                          </div>
                        </div>
                        <div className="col-span-2 flex gap-4 items-center justify-end flex-1 mt-2">
                          <div
                            className="icon-container-red h-min group"
                            onClick={() => setOpenDelete(true)}
                          >
                            <HiOutlineTrash className="icon-red" />
                          </div>
                          <div
                            className="icon-container-green h-min group "
                            onClick={handleSave}
                          >
                            <HiOutlineCheck className="icon-green" />
                          </div>
                        </div>
                        {promemoria !== "" && (
                          <>
                            <div className="col-span-2 h-1 bg-sinistri-lightgreen rounded-full"></div>

                            <div className="col-span-2 ">
                              <span className="text-base font-normal  flex items-center gap-1">
                                Promemoria <HiLockClosed className="" />
                              </span>
                              <div className="relative">
                                <span className="absolute left-2 flex items-start pr-2 pt-2 h-full">
                                  <HiOutlineBellAlert className="text-2xl" />
                                </span>
                                <textarea
                                  disabled
                                  type="text"
                                  name="appunti"
                                  className="input flex items-center"
                                  placeholder="Inserisci Promemoria"
                                  //onChange={handleChange}
                                  value={promemoria}
                                />
                              </div>
                            </div>
                          </>
                        )}
                        <div className="col-span-2 h-1 bg-sinistri-lightgreen rounded-full"></div>
                        <h3 className="col-span-2 flex text-xl justify-center">
                          IMPRESE
                        </h3>
                        <div className="col-span-2 flex gap-4 items-center justify-end flex-1 mt-2 h-80">
                          <DataGridPro
                            ignoreDiacritics
                            rows={relImprese}
                            columns={columnsImprese}
                            getRowId={(row) => row.id}
                            slots={{ toolbar: CustomToolbarImprese }}
                            onRowClick={(row) => {
                              setOperazioneImpresa("edit");
                              setImpresa(row.row);
                              setOpenImpresa(true);
                            }}
                            disableRowSelectionOnClick
                          />
                        </div>
                        <div className="col-span-2 h-1 bg-sinistri-lightgreen rounded-full"></div>
                        <h3 className="col-span-2 flex text-xl justify-center">
                          PERSONE INTERESSATE
                        </h3>
                        <div className="col-span-2 h-96">
                          <DataGridPro
                            ignoreDiacritics
                            //FILTRO IN BASE ALLASSOCIAZIONE DEL CONDOMINIO
                            rows={relPersoneInteressate}
                            columns={columnsPersone}
                            getRowId={(row) => row.id}
                            slots={{ toolbar: ToolbarPersoneInteressate }}
                            onRowClick={async (row) => {
                              setOperazionePersonaInteressata("edit");
                              let responsePersone = await api.get(
                                "persone_interessate/condominio/" +
                                  location.state?.sinistro?.id_condominio
                              );
                              setPersoneInteressate(responsePersone.data.data);
                              setPersonaInteressata({ ...row.row });
                              setOpenPersonaInteressata(true);
                            }}
                            disableRowSelectionOnClick
                          />
                        </div>
                        <div className="col-span-2 h-1 bg-sinistri-lightgreen rounded-full"></div>
                        <h3 className="col-span-2 flex text-xl justify-center">
                          STORICO EMAIL
                        </h3>
                        <div className="col-span-2 h-96">
                          <DataGridPro
                            ignoreDiacritics
                            //FILTRO IN BASE ALLASSOCIAZIONE DEL CONDOMINIO
                            rows={storicoEmail}
                            columns={columnsStorico}
                            onRowClick={(row) => {
                              setEmailSel(row.row);
                              setEmailOpen(true);
                            }}
                            getRowId={() => uuidv4()}
                            disableRowSelectionOnClick
                          />
                        </div>

                        <div className="col-span-2 h-1 bg-sinistri-lightgreen rounded-full"></div>

                        {sinistro?.co_assicurazione !== 0 && (
                          <>
                            <h3 className="col-span-2 flex text-xl justify-center">
                              CO ASSICURAZIONE
                            </h3>
                            <div className="col-span-2 flex gap-4 items-center justify-end flex-1 mt-2 h-80">
                              <DataGridPro
                                ignoreDiacritics
                                rows={relCoAss}
                                columns={columnsCoAssicurazione}
                                getRowId={(row) => row.id}
                                slots={{
                                  toolbar: CustomToolbarCoAssicurazione,
                                }}
                                onRowClick={(row) => {
                                  setOperazioneCoAss("edit");
                                  setCoAssicurazione(row.row);
                                  setOpenCoAssicurazione(true);
                                }}
                                disableRowSelectionOnClick
                              />
                            </div>
                          </>
                        )}
                      </div>
                      <Modal
                        open={emailOpen}
                        handleClose={async () => {
                          setEmailOpen(false);
                        }}
                      >
                        <h2 className="text-2xl text-center mb-4">Email</h2>
                        <div className="grid grid-cols-2">
                          <div className="col-span-2">
                            <Input
                              label={"Tipologia"}
                              type="text"
                              value={emailSel?.tipologia_mail}
                              icon={<HiOutlineQueueList className="text-2xl" />}
                            />
                            <Input
                              label={"Destinatario"}
                              type="text"
                              value={emailSel?.destinatario}
                              icon={<HiOutlineUser className="text-2xl" />}
                            />
                            <span className="text-base font-normal">Testo</span>
                            <div className="relative">
                              <span className="absolute left-2 flex items-start pr-2 pt-2 h-full">
                                <HiOutlineClipboard className="text-2xl" />
                              </span>
                              <textarea
                                disabled
                                rows={5}
                                type="text"
                                name="testo"
                                className="input flex items-center"
                                value={emailSel?.testo}
                              />
                            </div>
                          </div>
                          <div className="col-span-2 flex justify-end mt-4">
                            <div
                              className="icon-container-red h-min group"
                              onClick={() => setOpenDeleteEmail(true)}
                            >
                              <HiOutlineTrash className="icon-red" />
                            </div>
                          </div>
                        </div>
                      </Modal>
                      <Modal
                        open={openPersonaInteressata}
                        handleClose={handleClosePersone}
                      >
                        <div className="w-full py-5">
                          <div className="text-2xl text-center">
                            AGGIUNGI PERSONA INTERESSATA
                          </div>
                          <div className="grid grid-cols-2 gap-4 px-2 ">
                            <div className="col-span-2 md:col-span-2 flex items-end gap-5">
                              <Autocomplete
                                disabled={
                                  operazionePersonaInteressata === "edit"
                                }
                                suggestions={personeInteressate}
                                fields={["nome", "cognome", "telefono"]}
                                keyField="id"
                                value={
                                  personaInteressata?.id_persona_interessata
                                }
                                id="nome"
                                label="Persona"
                                name="nome"
                                onChangeFunc={(e) => {
                                  setPersonaInteressata({
                                    ...personaInteressata,
                                    id_persona_interessata: e,
                                  });
                                }}
                                icon={
                                  <HiOutlineUserGroup className="text-2xl" />
                                }
                              />
                              <div
                                className="icon-container-blue group h-min "
                                onClick={() => {
                                  navigate("/Dettaglio-Persona-Interessata", {
                                    state: {
                                      personaInteressata:
                                        personeInteressate?.filter(
                                          (pers) =>
                                            pers.id ===
                                            personaInteressata?.id_persona_interessata
                                        )[0],
                                      condominii: condominii,
                                    },
                                  });
                                }}
                              >
                                <HiOutlineExternalLink className="icon-blue" />
                              </div>
                              {operazionePersonaInteressata === "new" && (
                                <div
                                  className="icon-container-green group h-min "
                                  onClick={() => setOpenNewPersona(true)}
                                >
                                  <HiOutlinePlus className="icon-green" />
                                </div>
                              )}
                            </div>
                            <div className="col-span-2 md:col-span-2 ">
                              <Input
                                label={"Ordine"}
                                type="text"
                                name="ordine"
                                onChange={(e) =>
                                  setPersonaInteressata({
                                    ...personaInteressata,
                                    ordine: e.target.value,
                                  })
                                }
                                value={personaInteressata?.ordine}
                                icon={
                                  <HiOutlineQueueList className="text-2xl" />
                                }
                              />
                            </div>
                            <div className="col-span-2 flex gap-4 items-center justify-end flex-1 mt-2">
                              {operazionePersonaInteressata === "edit" && (
                                <div
                                  className="icon-container-red h-min group"
                                  onClick={() => setOpenDeletePersone(true)}
                                >
                                  <HiOutlineTrash className="icon-red" />
                                </div>
                              )}
                              {/* {operazionePersonaInteressata === "new" && ( */}
                              <div
                                className="icon-container-green h-min group "
                                onClick={handleSavePersona}
                              >
                                <HiOutlineCheck className="icon-green" />
                              </div>
                              {/* )} */}
                            </div>
                          </div>
                        </div>
                        <Modal
                          open={openNewPersona}
                          handleClose={async () => {
                            /* let responsePersone = await api.get(
                              "persone_interessate/condominio/" +
                                sinistro?.id_condominio
                            ); */
                            let responseRelPersone = await api.get(
                              "persone_interessate/sinistro/" +
                                location.state?.sinistro.id
                            );
                            let responsePersone = await api.get(
                              "persone_interessate/condominio/" +
                                location.state?.sinistro?.id_condominio
                            );
                            const differencePersoneInteressate =
                              responsePersone.data.data?.filter(
                                (persona) =>
                                  responseRelPersone.data.data
                                    ?.map(
                                      (relPersona) =>
                                        relPersona.id_persona_interessata
                                    )
                                    .indexOf(persona.id) === -1
                              );
                            if (
                              differencePersoneInteressate?.length !==
                              personeInteressate?.length
                            ) {
                              setPersonaInteressata({
                                ...personaInteressata,
                                //visto che la chiamata restituisce i dati ordinati per il cognome devo riordinare per id, così seleziono l'ultimo aggiunto
                                id_persona_interessata: findNewObject(
                                  differencePersoneInteressate,
                                  personeInteressate
                                )[0]?.id,
                              });
                            }
                            setOpenNewPersona(false);
                            getPersoneInteressate();
                          }}
                        >
                          <h2 className="text-2xl text-center mb-4">
                            Nuova Persona Interessata
                          </h2>
                          <NuovaPersonaInteressata
                            external={true}
                            idCondominio={sinistro?.id_condominio}
                            condominiiList={condominii}
                          />
                        </Modal>
                        <ModalDelete
                          open={openDeletePersone}
                          handleClose={() => setOpenDeletePersone(false)}
                          description={"Persona Interessata"}
                          handleDelete={handleDeletePersona}
                        />
                      </Modal>
                      <Modal
                        open={openNewStudio}
                        handleClose={async () => {
                          let responseStudio = await api.get("studi_peritali");
                          setStudiPeritali(responseStudio.data.data);
                          if (
                            responseStudio.data.data?.length !==
                            studiPeritali?.length
                          ) {
                            setSinistro({
                              ...sinistro,
                              id_studio_peritale:
                                /* responseStudio.data.data[
                                  responseStudio.data.data.length - 1
                                ]?.id, */
                                findNewObject(
                                  responseStudio.data.data,
                                  studiPeritali
                                )[0]?.id,
                            });
                          }
                          setOpenNewStudio(false);
                        }}
                      >
                        <h2 className="text-2xl text-center mb-4">
                          Nuovo Studio Peritale
                        </h2>
                        <NuovoStudioPeritale external={true} />
                      </Modal>
                      <Modal
                        open={openNewPerito}
                        handleClose={async () => {
                          let responsePeriti = await api.get(
                            "studi_peritali/periti/" +
                              sinistro?.id_studio_peritale
                          );
                          setPeriti(responsePeriti.data.data);
                          if (
                            responsePeriti.data.data?.length !== periti?.length
                          ) {
                            setSinistro({
                              ...sinistro,
                              id_perito:
                                /* responsePeriti.data.data[
                                  responsePeriti.data.data.length - 1
                                ]?.id, */
                                findNewObject(
                                  responsePeriti.data.data,
                                  periti
                                )[0]?.id,
                            });
                          }
                          setOpenNewPerito(false);
                        }}
                      >
                        <h2 className="text-2xl text-center mb-4">
                          Nuovo Perito
                        </h2>
                        <NuovoPerito
                          external={true}
                          idStudio={sinistro?.id_studio_peritale}
                        />
                      </Modal>
                      <ModalImprese
                        open={openImpresa}
                        close={handleCloseImpresa}
                        dati={impresa}
                        operazione={operazioneImpresa}
                        idSinistro={sinistro?.id}
                        imprese={imprese}
                      />
                      <ModalCoAssicurazione
                        open={openCoAssicurazione}
                        dati={coAssicurazione}
                        close={handleCloseCoAss}
                        operazione={operazioneCoAss}
                        idSinistro={sinistro?.id}
                        compagnie={compagnie}
                        agenzie={agenzie}
                        studiPeritali={studiPeritali}
                        periti={periti}
                      />
                      <ModalDelete
                        open={openDelete}
                        handleClose={() => setOpenDelete(false)}
                        description={nomeSinistro}
                        handleDelete={handleDelete}
                        /*explanation={
                      "Cancellando questo Condominio si elimineranno tutte le Persone Interessate collegate ad esso!"
                    } */
                      />
                      <ModalDelete
                        open={openDeleteEmail}
                        handleClose={() => setOpenDeleteEmail(false)}
                        description={emailSel?.tipologia_mail}
                        handleDelete={handleDeleteEmail}
                      />
                    </>
                  )}
                </>
              ),
            },
            {
              id: 3,
              child: (
                <>
                  {loadingDocumentazione ? (
                    <div className="flex flex-wrap gap-2 ">
                      <div className="flex w-full gap-2">
                        <Skeleton variant="rounded" width="40%" height={70} />
                        <Skeleton variant="rounded" width="60%" height={70} />
                      </div>
                      <div className="flex w-full gap-2">
                        <Skeleton variant="rounded" width="100%" height={30} />
                      </div>
                      <div className="flex w-full gap-2 justify-between">
                        <Skeleton variant="rounded" width="16%" height={100} />
                        <Skeleton variant="rounded" width="16%" height={100} />
                        <Skeleton variant="rounded" width="16%" height={100} />
                        <Skeleton variant="rounded" width="16%" height={100} />
                        <Skeleton variant="rounded" width="16%" height={100} />
                        <Skeleton variant="rounded" width="16%" height={100} />
                      </div>
                      <div className="flex w-full gap-2 justify-between">
                        <Skeleton variant="rounded" width="16%" height={100} />
                        <Skeleton variant="rounded" width="16%" height={100} />
                        <Skeleton variant="rounded" width="16%" height={100} />
                        <Skeleton variant="rounded" width="16%" height={100} />
                        <Skeleton variant="rounded" width="16%" height={100} />
                        <Skeleton variant="rounded" width="16%" height={100} />
                      </div>
                      <div className="flex w-full gap-2 justify-between">
                        <Skeleton variant="rounded" width="16%" height={100} />
                        <Skeleton variant="rounded" width="16%" height={100} />
                        <Skeleton variant="rounded" width="16%" height={100} />
                        <Skeleton variant="rounded" width="16%" height={100} />
                        <Skeleton variant="rounded" width="16%" height={100} />
                        <Skeleton variant="rounded" width="16%" height={100} />
                      </div>
                    </div>
                  ) : (
                    <>
                      {documentazione ? (
                        <ComponentDocumentazione
                          sinistro={sinistro}
                          doc={documentazione}
                          operazione="edit"
                          persone={congnomiPersone}
                          setTab={setTab}
                        />
                      ) : (
                        <ComponentDocumentazione
                          sinistro={location.state?.sinistro}
                          operazione="new"
                          persone={congnomiPersone}
                          getDoc={getDocumentazioni}
                          setTab={setTab}
                        />
                      )}
                    </>
                  )}
                </>
              ),
            },
            {
              id: 4,
              child: (
                <>
                  {loadingDenuncia ? (
                    <div className="flex flex-wrap gap-2">
                      <div className="flex w-full gap-2">
                        <Skeleton variant="rounded" width="50%" height={70} />
                      </div>
                      <div className="flex w-full gap-2">
                        <Skeleton variant="rounded" width="100%" height={30} />
                      </div>
                      <div className="flex w-full gap-2">
                        <Skeleton variant="rounded" width="100%" height={30} />
                        <Skeleton variant="rounded" width="50%" height={180} />
                        <Skeleton variant="rounded" width="50%" height={180} />
                        <Skeleton variant="rounded" width="50%" height={180} />
                        <Skeleton variant="rounded" width="50%" height={180} />
                      </div>
                    </div>
                  ) : (
                    <>
                      {denunce?.length > 0 ? (
                        <ComponentDenuncia
                          dati={denunce[0]}
                          storico={denunce}
                          operazione="edit"
                          cognomiPersone={congnomiPersone}
                          sinistro={{
                            ...location.state?.sinistro,
                            ...sinistro,
                          }}
                          getDenunce={getDenunce}
                          setTab={setTab}
                        />
                      ) : (
                        <ComponentDenuncia
                          operazione="new"
                          cognomiPersone={congnomiPersone}
                          setTab={setTab}
                          sinistro={{
                            ...location.state?.sinistro,
                            ...sinistro,
                          }}
                        />
                      )}
                    </>
                  )}
                </>
              ),
            },
            {
              id: 5,
              child: (
                <>
                  {loadingLiquidazione ? (
                    <div className="flex flex-wrap gap-2">
                      <div className="flex w-full gap-2">
                        <Skeleton variant="rounded" width="40%" height={70} />
                        <Skeleton variant="rounded" width="10%" height={70} />
                      </div>
                      <div className="flex w-full gap-2">
                        <Skeleton variant="rounded" width="100%" height={10} />
                      </div>
                      <div className="flex w-full gap-2">
                        <Skeleton variant="rounded" width="40%" height={70} />
                      </div>
                      <div className="flex w-full gap-2">
                        <Skeleton variant="rounded" width="50%" height={180} />
                        <Skeleton variant="rounded" width="50%" height={180} />
                      </div>
                      <div className="flex w-full gap-2">
                        <Skeleton variant="rounded" width="50%" height={180} />
                        <Skeleton variant="rounded" width="50%" height={180} />
                      </div>
                    </div>
                  ) : (
                    <>
                      {liquidazioni?.length > 0 ? (
                        <ComponentFattura
                          reloadLiquidazioni={async () => {
                            setLoadingLiquidazione(true);
                            /* FATTURA */
                            let responseLiquidazioni = await api.get(
                              "offerte/" + location.state?.sinistro.id
                            );
                            setLiquidazioni(responseLiquidazioni.data.data);
                            setLoadingLiquidazione(false);
                          }}
                          dati={liquidazioni}
                          sinistro={sinistro}
                          personeInteressate={relPersoneInteressate}
                          operazione={"edit"}
                        />
                      ) : (
                        <ComponentFattura
                          reloadLiquidazioni={async () => {
                            setLoadingLiquidazione(true);
                            /* FATTURA */
                            let responseLiquidazioni = await api.get(
                              "offerte/" + location.state?.sinistro.id
                            );
                            setLiquidazioni(responseLiquidazioni.data.data);
                            setLoadingLiquidazione(false);
                          }}
                          sinistro={sinistro}
                          personeInteressate={relPersoneInteressate}
                          operazione={"new"}
                        />
                      )}
                    </>
                  )}
                </>
              ),
            },
          ]}
        />
      </div>
      <ModalSM open={open} handleClose={() => setOpen(false)}>
        <div className={`col-span-2 duration-300 ${openSelect && "h-[400px]"}`}>
          <span className=" text-lg font-normal">Tipologia Mail</span>
          <div
            className="w-full flex space-x-4 items-center p-4 bg-white border-gray-400 hover:border-black border rounded-md cursor-pointer relative"
            onClick={() => setOpenSelect(!openSelect)}
          >
            <div className="w-full flex-1">
              {!emailType ? "Seleziona Tipologia Email" : emailType}
            </div>
            <HiOutlineChevronDown
              className={`ablsolute right-0 text-2xl duration-300 ${
                openSelect && "-rotate-180"
              }`}
            />
            <div
              className={`py-4 absolute top-[58px] -left-4 flex flex-col gap-0 bg-gray-100 border overflow-y-auto w-full rounded-md shadow-md ${
                openSelect ? "opacity-100 z-50" : "opacity-0 z-0 hidden"
              }`}
            >
              <div class="relative flex items-center justify-center w-full">
                <hr class="w-full border-t border-gray-300" />
                <span class="absolute px-2 bg-gray-100 text-gray-600">
                  GENERICHE
                </span>
              </div>
              <div
                className="flex py-2 px-4 gap-4 hover:bg-white"
                onClick={() => handleEmailType("Amministrazione")}
              >
                Amministrazione
              </div>
              <div
                className="flex py-2 pb-4 px-4 gap-4 hover:bg-white"
                onClick={() => handleEmailType("Pratica")}
              >
                Pratica(perito, agenzia, danneggiato)
              </div>
              <div class="relative flex items-center justify-center w-full">
                <hr class="w-full border-t border-gray-300" />
                <span class="absolute px-2 bg-gray-100 text-gray-600">
                  SOLLECITI
                </span>
              </div>
              {/* <div
                  className="flex py-2 px-4 gap-4 hover:bg-white"
                  onClick={() => handleEmailType("Documentazione")}
                >
                  Documentazione
                </div> */}
              <div
                className="flex py-2 px-4 gap-4 hover:bg-white"
                onClick={() => handleEmailType("Sinistro")}
              >
                Sinistro
              </div>
              <div
                className="flex py-2 px-4 gap-4 hover:bg-white"
                onClick={() => handleEmailType("Invio Atto")}
              >
                Invio Atto
              </div>
              <div
                className="flex py-2 px-4 gap-4 hover:bg-white"
                onClick={() => handleEmailType("Sollecito Atto Firmato")}
              >
                Sollecito Atto Firmato
              </div>
              <div
                className="flex py-2 px-4 gap-4 hover:bg-white"
                onClick={() => handleEmailType("Invio Proposta")}
              >
                Invio Proposta
              </div>
            </div>
          </div>
        </div>
        {/* <EmailMaker selectedSinistri={selectedSinistri}/> */}
      </ModalSM>
    </>
  );
};

const ModalImprese = ({
  open,
  close,
  operazione,
  dati,
  idSinistro,
  imprese,
}) => {
  const [impreseList, setImpreseList] = useState([]);
  const [impresa, setImpresa] = useState({
    id_impresa: null,
    id_sinistro: "",
    fattura: 0,
    n_fattura: "",
    anno: new Date(),
    intervento: "",
    id_intervento: 0,
    privato: 0,
    pagante: 0,
    dettaglio_pagamento: "",
    impresa_manuale: "",
    impresa: 0,
  });

  const [openNewImpresa, setOpenNewImpresa] = useState(false);

  const [openDelete, setOpenDelete] = useState(false);
  const [interventi, setInterventi] = useState([
    { id: 1, name: "" },
    { id: 2, name: "RG" },
    { id: 3, name: "AC" },
    { id: 4, name: "RG + AC" },
    { id: 5, name: "RISC (riscaldamento)" },
    { id: 6, name: "PREV (preventivo)" },
    { id: 7, name: "Sopralluogo" },
  ]);
  const [pagamenti, setPagamenti] = useState([
    { id: 1, name: "IMPRESA" },
    { id: 2, name: "CONDOMINIO" },
    { id: 3, name: "NESSUNO" },
    { id: 4, name: "ALTRO" },
  ]);

  const handleSave = async () => {
    if (operazione === "new") {
      try {
        await api.post("imprese/rel/", {
          impresa: {
            ...impresa,
            id_sinistro: idSinistro,
          },
        });
        toast.success("Salvataggio completato!");
        handleClose();
      } catch (error) {
        toast.error("Errore durante il salvataggio. Riprova!");
      }
    } else {
      try {
        await api.put("imprese/rel", {
          impresa: impresa,
        });
        toast.success("Modifica completata!");
        handleClose();
      } catch (error) {
        toast.error("Errore durante la modifica. Riprova!");
      }
    }
  };

  const handleDelete = async () => {
    try {
      await api.delete("imprese/rel/" + impresa?.id);
      toast.success("Eliminazione completata!");
      handleClose();
    } catch (error) {
      toast.error("Errore durante l'eliminazione. Riprova!");
    }
  };

  const handleChange = (e) => {
    setImpresa({ ...impresa, [e.target.name]: e.target.value });
  };

  const handleClose = () => {
    setImpresa({
      id_impresa: null,
      id_sinistro: "",
      fattura: 0,
      n_fattura: "",
      intervento: "",
      anno: new Date(),
      privato: "",
      impresa: 0,
      pagante: 0,
      dettaglio_pagamento: "",
    });
    close();
  };

  useEffect(() => {
    (async () => {
      if (operazione !== "new") {
        setImpresa({
          ...dati,
          id_intervento: interventi.filter(
            (int) => int?.name === dati?.intervento
          )[0]?.id,
        });
      }
      //let responseImpresa = await api.get("imprese/" + amministratore);
      let responseImpresa = await api.get("imprese");
      setImpreseList(responseImpresa.data.data);
    })();
  }, [dati, operazione, idSinistro]);

  return (
    <Modal open={open} handleClose={handleClose}>
      <div className="grid grid-cols-2 gap-2">
        <div className="col-span-2 text-2xl text-center">Impresa</div>
        <div className="col-span-2 md:col-span-1 ">
          <span className="text-lg font-normal">Anno</span>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DatePicker
              views={["year"]}
              openTo="year"
              className="w-full bg-white  hover:border-black border border-white text-black"
              onChange={(e) => {
                setImpresa({
                  ...impresa,
                  anno: moment(e).format("YYYY"),
                });
              }}
              value={moment(impresa?.anno)}
            />
          </LocalizationProvider>
        </div>
        <div className="col-span-2 md:col-span-1 flex items-end gap-2">
          <Autocomplete
            suggestions={impreseList}
            fields={["nome"]}
            keyField="id"
            value={impresa?.id_impresa}
            id="nome"
            label="Impresa"
            name="nome"
            onChangeFunc={(e) => {
              if (impreseList?.filter((el) => el.id === e)[0]?.pagante === 1) {
                setImpresa({ ...impresa, id_impresa: e, pagante: 1 });
              } else {
                setImpresa({ ...impresa, id_impresa: e, pagante: 0 });
              }
            }}
            icon={<HiOutlineBuildingLibrary className="text-2xl" />}
          />
          <div
            className="icon-container-green group "
            onClick={() => setOpenNewImpresa(true)}
          >
            <HiOutlinePlus className="icon-green" />
          </div>
        </div>
        <div className="col-span-2 md:col-span-1 ">
          <Select
            label={"Intervento"}
            placeholder="Seleziona Intervento"
            options={interventi}
            disabled={false}
            value={impresa?.id_intervento}
            onChange={(e) =>
              setImpresa({ ...impresa, intervento: e.target.value })
            }
            icon={<HiOutlineListBullet className="text-2xl" />}
          />
        </div>
        <div className="col-span-2 md:col-span-1 ">
          <Input
            label={"Importo"}
            type="text"
            name="fattura"
            onChange={handleChange}
            value={impresa?.fattura}
            icon={<HiOutlineCurrencyEuro className="text-2xl" />}
          />
        </div>

        <div className="col-span-2 md:col-span-1 ">
          <Input
            label={"N° Fattura"}
            type="text"
            name="n_fattura"
            onChange={handleChange}
            value={impresa?.n_fattura}
            icon={<HiOutlineCash className="text-2xl" />}
          />
        </div>
        <div className="col-span-2 md:col-span-1 ">
          <Checkbox
            label={"Privato"}
            name={"privato"}
            value={impresa?.privato === 1}
            onChange={() => {
              setImpresa({
                ...impresa,
                privato: impresa?.privato === 1 ? 0 : 1,
              });
            }}
          />
        </div>
        <div className="col-span-2 md:col-span-1 ">
          <SelectId
            label={"Pagante"}
            placeholder="Seleziona Pagante"
            options={pagamenti}
            disabled={false}
            value={impresa?.pagante}
            onChange={(e) =>
              setImpresa({ ...impresa, pagante: Number(e.target.value) })
            }
            icon={<HiOutlineUserCircle className="text-2xl" />}
          />
        </div>
        {impresa?.pagante === 4 && (
          <div className="col-span-2 md:col-span-1 ">
            <Input
              label={"Impresa Manuale"}
              type="text"
              name="impresa_manuale"
              onChange={handleChange}
              value={impresa?.impresa_manuale}
              icon={<HiOutlineBuildingLibrary className="text-2xl" />}
            />
          </div>
        )}
        {impresa?.pagante !== 0 && (
          <div className="col-span-2 md:col-span-1 ">
            <Input
              label={"Dettaglio Pagamento"}
              type="text"
              name="dettaglio_pagamento"
              onChange={handleChange}
              value={impresa?.dettaglio_pagamento}
              icon={<HiOutlineClipboard className="text-2xl" />}
            />
          </div>
        )}
        <div className="col-span-2 flex gap-4 items-center justify-end flex-1 mt-2">
          {operazione === "edit" && (
            <div
              className="icon-container-red h-min group"
              onClick={() => setOpenDelete(true)}
            >
              <HiOutlineTrash className="icon-red" />
            </div>
          )}
          <div
            className="icon-container-green h-min group "
            onClick={handleSave}
          >
            <HiOutlineCheck className="icon-green" />
          </div>
        </div>
      </div>
      <Modal
        open={openNewImpresa}
        handleClose={async () => {
          //let responseImpresa = await api.get("imprese/" + amministratore);
          let responseImpresa = await api.get("imprese");
          setImpreseList(responseImpresa.data.data);
          setOpenNewImpresa(false);
          if (responseImpresa.data.data?.length !== impreseList?.length) {
            setImpresa({
              ...impresa,
              id_impresa:
                /* responseImpresa.data.data[responseImpresa.data.data.length - 1]
                  ?.id, */
                findNewObject(responseImpresa.data.data, impreseList)[0]?.id,
            });
          }
        }}
      >
        <h2 className="text-2xl text-center mb-4">Nuova Impresa</h2>
        <NuovaImpresa external={true} />
      </Modal>
      <ModalDelete
        open={openDelete}
        handleClose={() => setOpenDelete(false)}
        description={impresa?.impresa}
        handleDelete={handleDelete}
      />
    </Modal>
  );
};
const ModalCoAssicurazione = ({
  open,
  close,
  operazione,
  dati,
  agenzie,
  compagnie,
  periti,
  studiPeritali,
  idSinistro,
}) => {
  const [peritiList, setPeritiList] = useState([]);
  const [compagnieList, setCompagnieList] = useState([]);
  const [agenzieList, setAgenzieList] = useState([]);
  const [studiPeritaliList, setStudiPeritaliList] = useState([]);

  const [coAssicurazione, setCoAssicurazione] = useState({
    id_compagnia_assicurativa: "",
    id_perito: "",
    id_studio_peritale: "",
    n_sinistro: "",
    liquidazione: 0,
    id_agenzia: "",
    appunti: "",
  });

  const [openNewCompagnia, setOpenNewCompagnia] = useState(false);
  const [openNewAgenzia, setOpenNewAgenzia] = useState(false);
  const [openNewStudio, setOpenNewStudio] = useState(false);
  const [openNewPerito, setOpenNewPerito] = useState(false);

  const [openDelete, setOpenDelete] = useState(false);

  const handleSave = async () => {
    if (operazione === "new") {
      try {
        await api.post("compagnie_assicurative/rel/", {
          compagnia: {
            ...coAssicurazione,
            id_sinistro: idSinistro,
          },
        });
        toast.success("Salvataggio completato!");
        handleClose();
      } catch (error) {
        toast.error("Errore durante il salvataggio. Riprova!");
      }
    } else {
      try {
        await api.put("compagnie_assicurative/rel", {
          compagnia: coAssicurazione,
        });
        toast.success("Modifica completata!");
        handleClose();
      } catch (error) {
        toast.error("Errore durante la modifica. Riprova!");
      }
    }
  };

  const handleClose = () => {
    setCoAssicurazione({
      id_compagnia_assicurativa: "",
      id_perito: "",
      id_studio_peritale: "",
      n_sinistro: "",
      liquidazione: 0,
      id_agenzia: "",
      note: "",
    });
    close();
  };

  const handleDelete = async () => {
    try {
      await api.delete("compagnie_assicurative/rel/" + coAssicurazione?.id);
      toast.success("Eliminazione completata!");
    } catch (error) {
      toast.error("Errore durante l'eliminazione. Riprova!");
    }
    handleClose();
  };

  const handleChange = (e) => {
    setCoAssicurazione({ ...coAssicurazione, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    (async () => {
      if (operazione !== "new") {
        setCoAssicurazione({ ...dati });
        let responsePeriti = await api.get(
          "studi_peritali/periti/" + dati.id_studio_peritale
        );
        setPeritiList(responsePeriti.data.data);
      }
      let responseCompagnie = await api.get("compagnie_assicurative");
      setCompagnieList(responseCompagnie.data.data);

      let responseAgenzie = await api.get("agenzie");
      setAgenzieList(responseAgenzie.data.data);

      let responseStudio = await api.get("studi_peritali");
      setStudiPeritaliList(responseStudio.data.data);
    })();
  }, [dati, operazione, idSinistro]);

  return (
    <Modal open={open} handleClose={handleClose}>
      <div className="grid grid-cols-2 gap-2">
        <div className="col-span-2 text-2xl text-center">Co Assicurazione</div>
        <div className="col-span-2 md:col-span-1 ">
          <Input
            label={"N° Sinistro"}
            type="text"
            name="n_sinistro"
            onChange={handleChange}
            value={coAssicurazione?.n_sinistro}
            icon={<HiOutlineListBullet className="text-2xl" />}
          />
        </div>
        <div className="col-span-2 md:col-span-1 flex items-end gap-2">
          <Autocomplete
            suggestions={compagnieList}
            fields={["nome"]}
            keyField="id"
            value={coAssicurazione?.id_compagnia_assicurativa}
            id="nome"
            label="Compagnia"
            name="nome"
            onChangeFunc={(e) => {
              setCoAssicurazione({
                ...coAssicurazione,
                id_compagnia_assicurativa: e,
              });
            }}
            icon={<HiOutlineBriefcase className="text-2xl" />}
          />
          <div
            className="icon-container-green group "
            onClick={() => setOpenNewCompagnia(true)}
          >
            <HiOutlinePlus className="icon-green" />
          </div>
        </div>
        <div className="col-span-2 md:col-span-1 flex items-end gap-2">
          <Autocomplete
            suggestions={studiPeritaliList}
            fields={["nome"]}
            keyField="id"
            value={coAssicurazione?.id_studio_peritale}
            id="nome"
            label="Studio Peritale"
            name="nome"
            onChangeFunc={async (e) => {
              setCoAssicurazione({
                ...coAssicurazione,
                id_studio_peritale: e,
              });
              let responsePeriti = await api.get("studi_peritali/periti/" + e);
              setPeritiList(responsePeriti.data.data);
            }}
            icon={<HiOutlineUser className="text-2xl" />}
          />
          <div
            className="icon-container-green group "
            onClick={() => setOpenNewStudio(true)}
          >
            <HiOutlinePlus className="icon-green" />
          </div>
        </div>
        <div className="col-span-2 md:col-span-1  flex items-end gap-2">
          <Autocomplete
            disabled={coAssicurazione?.id_studio_peritale === ""}
            suggestions={peritiList}
            fields={["cognome"]}
            keyField="id"
            value={coAssicurazione?.id_perito}
            id="cognome"
            label="Perito"
            name="cognome"
            onChangeFunc={(e) => {
              setCoAssicurazione({
                ...coAssicurazione,
                id_perito: e,
              });
            }}
            icon={<HiOutlineUser className="text-2xl" />}
          />
          {coAssicurazione?.id_studio_peritale && (
            <div
              className="icon-container-green group "
              onClick={() => setOpenNewPerito(true)}
            >
              <HiOutlinePlus className="icon-green" />
            </div>
          )}
        </div>
        <div className="col-span-2 md:col-span-1 flex items-end gap-2">
          <Autocomplete
            suggestions={agenzieList}
            fields={["nome"]}
            keyField="id"
            value={coAssicurazione?.id_agenzia}
            id="nome"
            label="Agenzia"
            name="nome"
            onChangeFunc={(e) => {
              setCoAssicurazione({
                ...coAssicurazione,
                id_agenzia: e,
              });
            }}
            icon={<HiOutlineUser className="text-2xl" />}
          />
          <div
            className="icon-container-green group "
            onClick={() => setOpenNewAgenzia(true)}
          >
            <HiOutlinePlus className="icon-green" />
          </div>
        </div>
        <div className="col-span-2 md:col-span-1 ">
          <Input
            label={"Liquidazione"}
            type="text"
            name="liquidazione"
            onChange={handleChange}
            value={coAssicurazione?.liquidazione}
            icon={<HiOutlineCurrencyEuro className="text-2xl" />}
          />
        </div>
        <div className="col-span-2 ">
          <span className="text-lg font-normal">Appunti</span>
          <div className="relative">
            <span className="absolute left-2 flex items-start pr-2 pt-2 h-full">
              <HiOutlineClipboard className="text-2xl" />
            </span>

            <textarea
              type="text"
              name="appunti"
              className="input flex items-center"
              placeholder="Inserisci Appunti"
              onChange={handleChange}
              value={coAssicurazione?.appunti}
            />
          </div>
        </div>
        <div className="col-span-2 flex gap-4 items-center justify-end flex-1 mt-2">
          {operazione === "edit" && (
            <div
              className="icon-container-red h-min group"
              onClick={() => setOpenDelete(true)}
            >
              <HiOutlineTrash className="icon-red" />
            </div>
          )}
          <div
            className="icon-container-green h-min group "
            onClick={handleSave}
          >
            <HiOutlineCheck className="icon-green" />
          </div>
        </div>
      </div>
      <Modal
        open={openNewCompagnia}
        handleClose={async () => {
          let responseCompagnia = await api.get("compagnie_assicurative");
          setCompagnieList(responseCompagnia.data.data);
          if (responseCompagnia.data.data?.length !== compagnieList?.length) {
            setCoAssicurazione({
              ...coAssicurazione,
              id_compagnia_assicurativa:
                /* responseCompagnia.data.data[
                  responseCompagnia.data.data.length - 1
                ]?.id, */
                findNewObject(responseCompagnia.data.data, compagnieList)[0]
                  ?.id,
            });
          }
          setOpenNewCompagnia(false);
        }}
      >
        <h2 className="text-2xl text-center mb-4">Nuova Compagnia</h2>
        <NuovaCompagnia external={true} />
      </Modal>
      <Modal
        open={openNewAgenzia}
        handleClose={async () => {
          let responseAgenzia = await api.get("agenzie");
          setAgenzieList(responseAgenzia.data.data);
          if (responseAgenzia.data.data?.length !== agenzieList?.length) {
            setCoAssicurazione({
              ...coAssicurazione,
              id_agenzia:
                /* responseAgenzia.data.data[responseAgenzia.data.data.length - 1]
                  ?.id, */
                findNewObject(responseAgenzia.data.data, agenzieList)[0]?.id,
            });
          }
          setOpenNewAgenzia(false);
        }}
      >
        <h2 className="text-2xl text-center mb-4">Nuova Agenzia</h2>
        <NuovaAgenzia external={true} />
      </Modal>
      <Modal
        open={openNewStudio}
        handleClose={async () => {
          let responseStudio = await api.get("studi_peritali");
          setStudiPeritaliList(responseStudio.data.data);
          if (responseStudio.data.data?.length !== studiPeritaliList?.length) {
            setCoAssicurazione({
              ...coAssicurazione,
              id_studio_peritale:
                /* responseStudio.data.data[responseStudio.data.data.length - 1]
                  ?.id, */
                findNewObject(responseStudio.data.data, studiPeritaliList)[0]
                  ?.id,
            });
          }
          setOpenNewStudio(false);
        }}
      >
        <h2 className="text-2xl text-center mb-4">Nuovo Studio Peritale</h2>
        <NuovoStudioPeritale external={true} />
      </Modal>
      <Modal
        open={openNewPerito}
        handleClose={async () => {
          let responsePeriti = await api.get(
            "studi_peritali/periti/" + coAssicurazione?.id_studio_peritale
          );
          setPeritiList(responsePeriti.data.data);
          if (responsePeriti.data.data?.length !== peritiList?.length) {
            setCoAssicurazione({
              ...coAssicurazione,
              id_perito:
                /* responsePeriti.data.data[responsePeriti.data.data.length - 1]
                  ?.id, */
                findNewObject(responsePeriti.data.data, peritiList)[0]?.id,
            });
          }
          setOpenNewPerito(false);
        }}
      >
        <h2 className="text-2xl text-center mb-4">Nuovo Perito</h2>
        <NuovoPerito
          external={true}
          idStudio={coAssicurazione?.id_studio_peritale}
        />
      </Modal>
      <ModalDelete
        open={openDelete}
        handleClose={() => setOpenDelete(false)}
        description={"Co Assicurazione"}
        handleDelete={handleDelete}
      />
    </Modal>
  );
};
