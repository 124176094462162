import React, { useEffect, useState } from "react";
import "./index.css";
import { BrowserRouter, Route, Routes, Link } from "react-router-dom";
import { Login } from "./pages/Login";

import { LicenseInfo } from "@mui/x-data-grid-pro";
import { AppLayout } from "./components/AppLayout";
import { Home } from "./pages/Home";
import { DettaglioPartica, NuovaPartica, Sinistri } from "./pages/Sinistri";
import {
  DettaglioDocumentazione,
  Documentazione,
  NuovaDocumentazione,
} from "./pages/Documentazione";
import { Denunce, DettaglioDenuncia, NuovaDenuncia } from "./pages/Denunce";
import { DettaglioPagamento, Pagamenti } from "./pages/Pagamenti";
import { DettaglioUtente, NuovoUtente, Utenti } from "./pages/Utenti";
import {
  DettaglioPersonaInteressata,
  NuovaPersonaInteressata,
  PersoneInteressate,
} from "./pages/PersoneInteressate";
import {
  Amministratori,
  DettaglioAmministratore,
  NuovoAmministratore,
} from "./pages/Amministratori";
import {
  Condominii,
  DettaglioCondominio,
  NuovoCondominio,
} from "./pages/Condominii";
import {
  Compagnie,
  DettaglioCompagnia,
  NuovaCompagnia,
} from "./pages/Compagnie";
import { DettaglioImpresa, Imprese, NuovaImpresa } from "./pages/Imprese";
import {
  DettaglioPerito,
  NuovoStudioPeritale,
  NuovoPerito,
  StudiPeritali,
  DettaglioStudioPeritale,
} from "./pages/Periti";
import {
  DettaglioTipologiaSinistro,
  NuovaTipologiaSinistro,
  TipologieSinistri,
} from "./pages/TipologieSinistri";
import {
  Promemoria,
  DettaglioPromemoria,
  NuovaPromemoria,
} from "./pages/Promemoria";
import { Amministratore } from "./utility/useAmministraotre";
import { Agenzie, DettaglioAgenzia, NuovaAgenzia } from "./pages/Agenzie";
import { DettaglioPolizza, NuovaPolizza } from "./pages/Polizze";
import PrivateRoute from "./components/PrivateRoute";
import { DettaglioGaranzia, Garanzie, NuovaGaranzia } from "./pages/Garanzie";
import { InvioEmail } from "./pages/InvioEmail";
import {
  DettaglioTestoEmail,
  NuovoTestoEmail,
  TestiEmail,
} from "./pages/TestiEmail";
const moment = require("moment-timezone");

moment.tz.setDefault("Europe/Rome");

LicenseInfo.setLicenseKey(
  "7de2641e5b9a08694469a2d7eb4ed7dbTz0xMDE1ODAsRT0xNzY3NTMyNDQ1MDAwLFM9cHJvLExNPXN1YnNjcmlwdGlvbixQVj1pbml0aWFsLEtWPTI="
);

function App() {
  //const [context, setContext] = useState(0);

  const [context, setContext] = useState(() => {
    // Load from sessionStorage, or use a default context if not available
    return JSON.parse(sessionStorage.getItem("amministratore"));
  });

  useEffect(() => {
    // Save to sessionStorage whenever `context` changes
    sessionStorage.setItem("amministratore", JSON.stringify(context));
  }, [context]);

  return (
    <>
      <Amministratore.Provider value={[context, setContext]}>
        <BrowserRouter>
          <Routes>
            <Route path="/Login" element={<Login />} />
            {/* <Route element={<RequireAuth />}> */}
            <Route
              path="/"
              element={
                <PrivateRoute>
                  <AppLayout />
                </PrivateRoute>
              }
            >
              <Route
                path="/Home"
                element={
                  <PrivateRoute>
                    <Home />
                  </PrivateRoute>
                }
              />
              <Route
                path="/Sinistri"
                element={
                  <PrivateRoute>
                    <Sinistri />
                  </PrivateRoute>
                }
              />
              <Route path="/Nuovo-Sinistro" element={<NuovaPartica />} />
              <Route
                path="/Dettaglio-Sinistro"
                element={<DettaglioPartica />}
              />
              <Route path="/Documentazione" element={<Documentazione />} />
              <Route
                path="/Nuova-Documentazione"
                element={<NuovaDocumentazione />}
              />
              <Route
                path="/Dettaglio-Documentazione"
                element={<DettaglioDocumentazione />}
              />
              <Route path="/Denunce" element={<Denunce />} />
              <Route
                path="/Dettaglio-Denuncia"
                element={<DettaglioDenuncia />}
              />
              <Route path="/Nuova-Denuncia" element={<NuovaDenuncia />} />
              <Route path="/Pagamenti" element={<Pagamenti />} />
              <Route
                path="/Dettaglio-Pagamento"
                element={<DettaglioPagamento />}
              />
              <Route path="/Utenti" element={<Utenti />} />
              <Route path="/Nuovo-Utente" element={<NuovoUtente />} />
              <Route path="/Dettaglio-Utente" element={<DettaglioUtente />} />
              <Route
                path="/Persone-Interessate"
                element={<PersoneInteressate />}
              />
              <Route
                path="/Dettaglio-Persona-Interessata"
                element={<DettaglioPersonaInteressata />}
              />
              <Route
                path="/Nuova-Persona-Interessata"
                element={<NuovaPersonaInteressata />}
              />
              <Route path="/Amministratori" element={<Amministratori />} />
              <Route
                path="/Dettaglio-Amministratore"
                element={<DettaglioAmministratore />}
              />
              <Route
                path="/Nuovo-Amministratore"
                element={<NuovoAmministratore />}
              />
              <Route path="/Condominii" element={<Condominii />} />
              <Route
                path="/Dettaglio-Condominio"
                element={<DettaglioCondominio />}
              />
              <Route path="/Nuovo-Condominio" element={<NuovoCondominio />} />
              <Route path="/Dettaglio-Polizza" element={<DettaglioPolizza />} />
              <Route path="/Nuova-Polizza" element={<NuovaPolizza />} />
              <Route path="/Compagnie" element={<Compagnie />} />
              <Route
                path="/Dettaglio-Compagnia"
                element={<DettaglioCompagnia />}
              />
              <Route path="/Nuova-Compagnia" element={<NuovaCompagnia />} />
              <Route path="/Agenzie" element={<Agenzie />} />
              <Route path="/Dettaglio-Agenzia" element={<DettaglioAgenzia />} />
              <Route path="/Nuova-Agenzia" element={<NuovaAgenzia />} />
              <Route path="/Imprese" element={<Imprese />} />
              <Route path="/Dettaglio-Impresa" element={<DettaglioImpresa />} />
              <Route path="/Nuova-Impresa" element={<NuovaImpresa />} />
              <Route path="/Studi-Peritali" element={<StudiPeritali />} />
              <Route
                path="/Dettaglio-Studio-Peritale"
                element={<DettaglioStudioPeritale />}
              />
              <Route
                path="/Nuovo-Studio-Peritale"
                element={<NuovoStudioPeritale />}
              />
              <Route path="/Dettaglio-Perito" element={<DettaglioPerito />} />
              <Route path="/Nuovo-Perito" element={<NuovoPerito />} />
              <Route
                path="/Tipologie-Sinistri"
                element={<TipologieSinistri />}
              />
              <Route
                path="/Dettaglio-Tipologia-Sinistro"
                element={<DettaglioTipologiaSinistro />}
              />
              <Route
                path="/Nuova-Tipologia-Sinistro"
                element={<NuovaTipologiaSinistro />}
              />
              <Route path="/Testi-Email" element={<TestiEmail />} />
              <Route
                path="/Dettaglio-Testo-Email"
                element={<DettaglioTestoEmail />}
              />
              <Route path="/Nuovo-Testo-Email" element={<NuovoTestoEmail />} />
              <Route path="/Garanzie" element={<Garanzie />} />
              <Route
                path="/Dettaglio-Garanzia"
                element={<DettaglioGaranzia />}
              />
              <Route path="/Nuova-Garanzia" element={<NuovaGaranzia />} />
              <Route path="/Promemoria" element={<Promemoria />} />
              <Route
                path="/Dettaglio-Promemoria"
                element={<DettaglioPromemoria />}
              />
              <Route path="/Nuova-Promemoria" element={<NuovaPromemoria />} />
              <Route path="/Invio-Email" element={<InvioEmail />} />
            </Route>
            <Route path="*" element={<NotFoundPage />} />
            {/* </Route> */}
          </Routes>
        </BrowserRouter>
      </Amministratore.Provider>
    </>
  );
}

export default App;

const NotFoundPage = () => {
  return (
    <div className="w-full h-[100vh] flex flex-col bg-sinistri-blue text-white justify-center items-center">
      <h1 className="text-9xl font-semibold">404</h1>
      <h3 className="text-2xl font-semibold">PAGINA NON TROVATA</h3>
      <Link to="/Home" className="text-2xl font-light underline cursor-pointer">
        HOMEPAGE
      </Link>
    </div>
  );
};
